import React from 'react';
import { IconElement } from './icon.styled';
import { Icons } from './svgs';

const DEFAULT_ICON_SIZE = '20px';

interface IconProps {
  type: string;
  fill?: string;
  size?: string;
}

export function Icon({ type, fill, size = DEFAULT_ICON_SIZE }: IconProps) {
  const SelectedIcon = Icons[type];

  if (!SelectedIcon) {
    console.error(`Icon with type ${type} does not exist!`);
    return <IconElement as={Icons.Website} $size={size} $fill={fill} />;
  }

  return <IconElement as={SelectedIcon} $size={size} $fill={fill} />;
}
