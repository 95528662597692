import React from 'react';

interface ImageProps {
  src: string;
  alt?: string;
  height?: number;
  width?: number;
  excludeCdn?: boolean;
}

export const generateImagePath = (path: string, format?: string, height?: number, width?: number) => {
  return `https://pata.pet/cdn-cgi/image/width=${width},height=${height}${format && `,format=${format}`}/${path}`;
};

export function Image({ src, alt, width = 500, height = 500, excludeCdn = false }: ImageProps) {
  return <img alt={alt} src={excludeCdn ? src : generateImagePath(src, 'auto', width, height)} />;
}
