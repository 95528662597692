import styled from 'styled-components';

export const CustomEventContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.core.sizing[0]}px;

  strong {
    font-weight: ${({ theme }) => theme.core.fontWeight.medium};
  }
`;

export const BlockedTimeContainer = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.core.sizing[0]}px;
`;
