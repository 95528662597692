import { Modal } from '@pata-app/ui-modal';
import { useTranslation } from 'react-i18next';
import React, { useMemo } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { type TimeOff } from '../../../../types/member.types';
import { useNavigateWithSearchParams } from '../../../../hooks/use-navigate-with-search-params';
import { AddEditBlockTimeForm } from './add-edit-block-time-form';

export function AddEditBlockTime() {
  const { blockTimeId } = useParams();

  const { t } = useTranslation();

  const navigate = useNavigateWithSearchParams();

  const [searchParams] = useSearchParams();

  const memberId = searchParams.get('blt-mbr-id');
  const startDate = searchParams.get('blt-start-date');
  const startTime = searchParams.get('blt-start-time');

  const handleCloseModal = () => {
    navigate('/calendar', undefined, undefined, ['blt-mbr-id', 'blt-start-date', 'blt-start-time']);
  };

  const form = useForm<TimeOff>({
    defaultValues: {
      type: undefined,
      startDate: startDate ?? undefined,
      endDate: startDate ?? undefined,
      startTime: startTime ? Number(startTime) : undefined,
      endTime: startTime ? Number(startTime) + 3600 : undefined,
      reason: undefined,
      memberId: memberId ?? undefined,
    },
  });

  // useEffect(() => {
  //   if (data) {
  //     const editData = {
  //       memberId,
  //       startDate: data.startDate,
  //       endDate: data.endDate,
  //       endTime: data.endTime,
  //       startTime: data.startTime,
  //       reason: data.reason,
  //       timeOffType: {
  //         id: data.timeOffType,
  //       },
  //     };
  //     reset(editData);
  //   }
  // }, [data, reset]);

  const modalActions = useMemo(
    () => [
      {
        label: t('cancel', { ns: 'common' }),
        onClick: handleCloseModal,
        buttonType: 'secondary',
      },
      {
        label: t('Add', { ns: 'common' }),
        onClick: () => {},
        buttonType: 'cta',
      },
    ],
    [handleCloseModal, t],
  );

  return (
    <Modal
      actions={modalActions}
      handleToggleModal={handleCloseModal}
      isOpen
      modalTitle={blockTimeId ? 'Edit Block Time' : 'Add Block Time'}
      sizing={{
        width: '600px',
        maxWidth: '600px',
        minWidth: '600px',
        height: '800px',
        maxHeight: `calc(100vh - 50px)`,
      }}
    >
      <AddEditBlockTimeForm form={form} />
    </Modal>
  );
}
