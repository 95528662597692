import { type Method } from 'axios';
import { methods, request } from '../lib';
import { type PaymentAccount } from '../types/payments.types';
import { endpoints } from './endpoints';

export async function getPaymentAccount(): Promise<PaymentAccount> {
  const data = await request({
    endpoint: endpoints.payments.all,
    method: methods.GET as Method,
  });

  return data?.data?.data;
}

export async function postPaymentAccount(body: { bankAccount: string }): Promise<PaymentAccount> {
  const data = await request({
    endpoint: endpoints.payments.all,
    method: methods.POST as Method,
    body,
  });

  return data.data.data;
}

export async function putPaymentProtection(protectionType: string, protectionDetails: any): Promise<PaymentAccount> {
  const data = await request({
    endpoint: endpoints.payments.protection.type(protectionType),
    method: methods.PUT as Method,
    body: protectionDetails,
  });

  return data.data.data;
}

export async function deletePaymentProtection() {
  await request({
    endpoint: endpoints.payments.protection.all,
    method: methods.DELETE as Method,
  });
}


