import React from 'react';
import { navigationConfig } from '../../../routes.config';
import { MenuItem } from '../../../components/menu-item/menu-item';

export function ExtendedMenu(): JSX.Element {
  const extendedMenuOptions = navigationConfig.filter((option) => !option.quickLink);

  return (
    <div>
      {extendedMenuOptions.map((option) => (
        <MenuItem key={option.path} {...option} />
      ))}
    </div>
  );
}
