import React, { useMemo } from 'react';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { FormLayout, selectStyles, DateInput, FormField, TextArea, TimeInput, Input } from '@pata-app/ui-form';
import { Controller, type UseFormReturn } from 'react-hook-form';
import Select from 'react-select';
import { useGetMembers } from '../../../../queries/member.queries';
import { FormContainer } from '../../../../components/layouts/app-layout/components/form-container/form-container';
import { type BlockTime } from '../../../../types/appointments.types';
import { BLOCK_TIME_OPTIONS } from './add-edit-block-time.config';

interface AddEditBlockTimeFormProps {
  form: UseFormReturn<BlockTime>;
}

export function AddEditBlockTimeForm({ form }: AddEditBlockTimeFormProps) {
  const { t } = useTranslation('common');

  const [searchParams] = useSearchParams();

  const workplaceId = searchParams.get('clr-wpl-id');

  const {
    control,
    register,
    formState: { errors },
    getValues,
    setValue,
    watch,
  } = form;

  const { data } = useGetMembers('members', '');

  const members = data?.data;

  const memberOptions = useMemo(
    () =>
      members?.reduce((validMembers, member) => {
        const memberWorkplaceIds = member.workplaces.map((workplace) => workplace.id);

        if (memberWorkplaceIds.includes(workplaceId)) {
          return [
            ...validMembers,
            {
              value: member.id,
              label: `${member.firstName} ${member.lastName}`,
            },
          ];
        }

        return validMembers;
      }, []),
    [members, workplaceId],
  );

  const hasOtherTypeSelected = watch('timeOffType')?.id === 'other';

  return (
    <FormContainer hasSections={false}>
      <FormLayout columnConfig={{ m: 2 }}>
        <FormField error={errors.memberId?.message} label={t('team_member', { ns: 'team' })}>
          <Controller
            control={control}
            name="memberId"
            render={({ field }) => {
              return (
                <Select
                  {...field}
                  onChange={(event) => {
                    field.onChange(event?.value);
                  }}
                  options={memberOptions ?? []}
                  styles={selectStyles}
                  value={memberOptions?.find((option) => option.value === field.value)}
                />
              );
            }}
            rules={{
              required: 'A Team Member is required',
            }}
          />
        </FormField>

        <FormField error={errors.timeOffType?.message} label={t('block_time_type', { ns: 'appointments' })}>
          <Controller
            control={control}
            name="timeOffType"
            render={({ field }) => {
              return (
                <Select
                  {...field}
                  onChange={(event) => {
                    field.onChange({ id: event?.value });
                  }}
                  options={BLOCK_TIME_OPTIONS}
                  styles={selectStyles}
                  value={BLOCK_TIME_OPTIONS.find((option) => option.value === field.value?.id)}
                />
              );
            }}
            rules={{
              required: 'A Leave Type is required',
            }}
          />
        </FormField>
      </FormLayout>

      {Boolean(hasOtherTypeSelected) && (
        <FormField error={errors.title?.message} label={t('title')}>
          <Input
            {...register('title', {
              required: 'A Title is required',
              maxLength: { value: 50, message: 'Max character length is 50' },

              setValueAs: (value: string) => value.trim(),
            })}
            hasError={Boolean(errors.title)}
          />
        </FormField>
      )}

      <FormLayout columnConfig={{ m: 2 }}>
        <FormField error={errors.startDate?.message} label={t('start_date')}>
          <Controller
            control={control}
            name="startDate"
            render={({ field }) => {
              return (
                <DateInput
                  {...field}
                  fromYear={new Date().getFullYear() - 30}
                  onChange={(value: string) => {
                    field.onChange(value);

                    if (DateTime.fromISO(value).toSeconds() > DateTime.fromISO(getValues('endDate')).toSeconds()) {
                      setValue('endDate', value);
                    }
                  }}
                  toYear={new Date().getFullYear()}
                  value={field.value}
                />
              );
            }}
            rules={{ required: 'A Start Date is required' }}
          />
        </FormField>

        <FormField error={errors.startTime?.message} label={t('start_time')}>
          <Controller
            control={control}
            name="startTime"
            render={({ field }) => {
              return (
                <TimeInput
                  {...field}
                  onChange={(event) => {
                    field.onChange(Number(event.target.value));
                  }}
                  timesTo={getValues('startDate') === getValues('endDate') ? getValues('endTime') : undefined}
                />
              );
            }}
            rules={{ required: 'A Start Time is required' }}
          />
        </FormField>
      </FormLayout>

      <FormLayout columnConfig={{ m: 2 }}>
        <FormField error={errors.endDate?.message} label={t('end_date')}>
          <Controller
            control={control}
            name="endDate"
            render={({ field }) => {
              return (
                <DateInput
                  {...field}
                  disabled={[
                    {
                      before: DateTime.fromISO(getValues('startDate')).toISODate(),
                    },
                  ]}
                  fromYear={new Date().getFullYear() - 30}
                  onChange={(value: string) => {
                    field.onChange(value);
                  }}
                  toYear={new Date().getFullYear()}
                  value={field.value}
                />
              );
            }}
            rules={{ required: 'An End Date is required' }}
          />
        </FormField>

        <FormField error={errors.endTime?.message} label={t('end_time')}>
          <Controller
            control={control}
            name="endTime"
            render={({ field }) => {
              return (
                <TimeInput
                  {...field}
                  onChange={(event) => {
                    field.onChange(Number(event.target.value));
                  }}
                  timesFrom={getValues('startDate') === getValues('endDate') ? getValues('startTime') : undefined}
                />
              );
            }}
            rules={{ required: 'An End Time is required' }}
          />
        </FormField>
      </FormLayout>

      <FormField error={errors.description?.message} label={t('note')}>
        <Controller
          control={control}
          name="description"
          render={({ field }) => {
            return (
              <TextArea
                {...field}
                hasError={Boolean(errors.description)}
                maxCharacterLength={500}
                placeholder="Add a description for the block time"
              />
            );
          }}
          rules={{
            maxLength: { value: 500, message: 'Max character length is 500' },
          }}
        />
      </FormField>
    </FormContainer>
  );
}
