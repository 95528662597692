function getCharCodeSum(str: string): number {
  let sum = 0;
  for (let i = 0; i < str.length; i++) {
    sum += str.charCodeAt(i);
  }
  return sum;
}

export function getColourBasedOnString(name?: string, colors: string[]): string {
  const sum = name && getCharCodeSum(name);
  const index = sum && sum % colors.length;


  if (index || index === 0) {
    return colors[Math.min(index, colors.length - 1)];
  }
}
