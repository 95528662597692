import { useMemo, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { routesConfig } from '../routes.config';

export function useMenuPath() {
  const location = useLocation();
  const navigate = useNavigate();

  const currentPrimaryRoute = useMemo(
    () => (location.pathname && location.pathname.match(/^\/([^/]+)/) ? location.pathname.match(/^\/([^/]+)/)[1] : ''),
    [location.pathname],
  );

  const [_, _first, currentSubMenuRoute] = useMemo(
    () => location.pathname.match(/^\/([^/]+)\/([^/]+)/) || [],
    [location.pathname],
  );

  const currentPrimaryMenuConfig = useMemo(
    () => routesConfig.find((mainItem) => mainItem.path === currentPrimaryRoute),
    [currentPrimaryRoute],
  );

  const currentSubMenuConfig = useMemo(() => currentPrimaryMenuConfig?.subMenu, [currentPrimaryMenuConfig]);

  const hasSelectedRoute = !currentSubMenuConfig || Boolean(currentSubMenuRoute);

  const currentRoutesConfig = useMemo(
    () =>
      currentSubMenuRoute
        ? routesConfig
            .find((item) => item.path === currentPrimaryRoute)
            ?.subMenu?.find((subMenuItem) => subMenuItem.path === currentSubMenuRoute)
        : routesConfig.find((item) => item.path === currentPrimaryRoute),
    [currentSubMenuRoute, currentPrimaryRoute],
  );

  const currentPathName = location.pathname;

  const handleSelect = useCallback(
    (selected: string) => {
      navigate(`/${currentPrimaryRoute}/${selected}`);
    },
    [navigate, currentPrimaryRoute],
  );

  return {
    currentPrimaryRoute,
    currentSubMenuRoute,
    currentPrimaryMenuConfig,
    currentSubMenuConfig,
    handleSelect,
    hasSelectedRoute,
    currentPathName,
    currentRoutesConfig,
  };
}
