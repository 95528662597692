import styled from 'styled-components';

export const AuthFormContainerStyled = styled.form`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.core.sizing[2]}px;

  width: 100%;

  h1 {
    display: flex;
    flex-direction: column;

    margin: 0;

    font-family: ${({ theme }) => theme.core.fontFamily.jost};
    font-size: ${({ theme }) => theme.core.fontSizes.xxxl}px;

    br {
      height: 16px;
    }
  }

  h2 {
    margin: 0;

    font-size: ${({ theme }) => theme.core.fontSizes.m}px;
    font-weight: ${({ theme }) => theme.core.fontWeight.regular};
    color: ${({ theme }) => theme.core.colors.greyscale.dark};
  }

  button {
    width: 100%;
  }

  svg {
    color: ${({ theme }) => theme.core.colors.primary.green.medium};
  }

  hr {
    width: 100%;
    color: ${({ theme }) => theme.core.colors.greyscale.light};
  }
`;
