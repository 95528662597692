import { type UseQueryResult, useQuery, useMutation, type UseQueryOptions, type QueryKey } from 'react-query';
import {
  type ClientsSearch,
  type Client,
  type Note,
  type ClientNotesSearch,
  type ClientWalletSearch,
  type WalletItem,
} from '../types/client.types';
import {
  deleteClient,
  deleteClientNote,
  getClient,
  getClientNotes,
  getClients,
  getClientWallet,
  getClientWalletItem,
  patchClient,
  patchClientNote,
  postClient,
  postClientNote,
} from '../api/client.api';
import { useGetUser } from './user.queries';
import { queryClient } from './query-client';

export function useGetClients(
  queryKey: QueryKey,
  queryString: string,
  options?: UseQueryOptions<ClientsSearch, Error>,
): UseQueryResult<ClientsSearch> {
  const { data } = useGetUser();

  const accountId = data?.accounts?.[0];

  return useQuery<ClientsSearch, Error>(queryKey, () => getClients(queryString), {
    enabled: Boolean(accountId),
    ...options,
  });
}

export function useGetClient(
  clientId: string | null,
  options?: UseQueryOptions<Client, Error>,
): UseQueryResult<Client> {
  return useQuery<Client, Error>(['client', clientId], () => getClient(clientId as string), {
    staleTime: 100000,
    enabled: Boolean(clientId),
    ...options,
  });
}

export const usePatchClient = () => {
  return useMutation('patchClient', {
    mutationFn: (data: { id: string; clientDetails: Partial<Client> }) => patchClient(data.id, data.clientDetails),
  });
};

export const useDeleteClient = () => {
  return useMutation('deleteClient', {
    mutationFn: (id: string) => deleteClient(id),
  });
};

export const usePostClient = () => {
  return useMutation('postClient', {
    mutationFn: (clientDetails: Partial<Client>) => postClient(clientDetails),
  });
};

export function useGetClientNotes(
  clientId: string,
  queryKey: QueryKey,
  queryString?: string,
  options?: UseQueryOptions<ClientNotesSearch, Error>,
): UseQueryResult<ClientNotesSearch> {
  const { data } = useGetUser();

  const accountId = data?.accounts[0];

  return useQuery<ClientNotesSearch, Error>(queryKey, () => getClientNotes(clientId, queryString), {
    enabled: Boolean(accountId) && Boolean(clientId),
    ...options,
  });
}

export const usePatchClientNote = () => {
  return useMutation('patchClientNote', {
    mutationFn: (data: { clientId: string; noteId: string; clientDetails: Partial<Note> }) =>
      patchClientNote(data.clientId, data.noteId, data.clientDetails),
  });
};

export const useDeleteClientNote = () => {
  return useMutation('deleteClientNote', {
    mutationFn: (data: { clientId: string; noteId: string }) => deleteClientNote(data.clientId, data.noteId),
  });
};

export const usePostClientNote = () => {
  return useMutation('postClientNote', {
    mutationFn: (data: { clientId: string; clientDetails: Partial<Note> }) =>
      postClientNote(data.clientId, data.clientDetails),
  });
};

export function useGetClientWallet(
  clientId: string,
  queryKey: QueryKey,
  queryString?: string,
  options?: UseQueryOptions<ClientWalletSearch, Error>,
): UseQueryResult<ClientWalletSearch> {
  const { data } = useGetUser();

  const accountId = data?.accounts[0];

  return useQuery<ClientWalletSearch, Error>(queryKey, () => getClientWallet(clientId, queryString), {
    enabled: Boolean(accountId) && Boolean(clientId),
    ...options,
  });
}
export function useGetClientWalletItem(
  clientId: string,
  itemId: string,
  queryKey: QueryKey,
  queryString?: string,
  options?: UseQueryOptions<WalletItem, Error>,
): UseQueryResult<WalletItem> {
  const { data } = useGetUser();

  const accountId = data?.accounts[0];

  return useQuery<WalletItem, Error>(queryKey, () => getClientWalletItem(clientId, itemId, queryString), {
    enabled: Boolean(accountId) && Boolean(clientId) && Boolean(itemId),
    ...options,
  });
}

export const prefetchClients = async () => {
  await queryClient.prefetchQuery({
    queryKey: ['clients', null],
    queryFn: () => getClients(),
  });
};
