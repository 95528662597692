import React from 'react';
import { CheckboxContainer, HiddenCheckbox, Icon, StyledCheckbox } from './checkbox.styled';

interface CheckboxProps extends HTMLInputElement {
  checked: boolean;
  size: number;
}

export function Checkbox({ checked, size = 20, ...props }: CheckboxProps) {
  return (
    <CheckboxContainer $size={size}>
      <HiddenCheckbox checked={checked} {...props} />
      <StyledCheckbox $checked={checked} $size={size}>
        <Icon viewBox="0 0 24 24">
          <polyline points="20 6 9 17 4 12" />
        </Icon>
      </StyledCheckbox>
    </CheckboxContainer>
  );
}
