import React from 'react';
import { AccountIcon } from '../../account-icon/account-icon';
import { useGetUser } from '../../../../../../../../../../queries/user.queries';
import { ProfileIcon } from '../../../../../../../../../profile-icon/profile-icon';
import { AccountMenuOverviewContainer, UserOverviewContainer } from './account-menu-overview.styled';

export function AccountMenuOverview() {
  const { data } = useGetUser();

  return (
    <AccountMenuOverviewContainer>
      <UserOverviewContainer>
        {data?.profilePicture ? (
          <ProfileIcon
            excludeCdn={data.userType === 'google'}
            firstName={data.firstName}
            lastName={data.lastName}
            profilePicture={data.profilePicture}
          />
        ) : (
          <AccountIcon initial={data?.firstName[0] ?? '-'} />
        )}
        <span>{`${data?.firstName} ${data?.lastName}`}</span>
      </UserOverviewContainer>
    </AccountMenuOverviewContainer>
  );
}
