import { createContext, useContext } from 'react';

interface AccountHeaderContext {
  showAccountMenu: boolean;
  setShowAccountMenu: (toggle: boolean) => void;
}

export const AccountHeaderContext = createContext<AccountHeaderContext>({
  showAccountMenu: false,
  setShowAccountMenu: () => {},
});

export function useAccountHeaderContext(): AccountHeaderContext {
  return useContext(AccountHeaderContext);
}
