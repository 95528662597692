import { type UseQueryResult, useQuery, useMutation, type UseQueryOptions } from 'react-query';
import { fetchAuthSession } from 'aws-amplify/auth';
import {
  getResetId,
  getUser,
  getUserByEmail,
  patchUser,
  postPasswordReset,
  postUserForgottenPassword,
} from '../api/user.api';
import { type User } from '../types/user.types';

export function useGetUser(options?: UseQueryOptions<User, Error>): UseQueryResult<User> {
  const query = useQuery<User, Error>('user', getUser, {
    staleTime: 10000,
    retry: false,
    ...options,
  });

  const { data } = query;

  if (data?.accounts?.length) {
    const accountId = data.accounts[0];

    if (accountId) {
      localStorage.setItem('accountId', accountId);
    }
  }

  return query;
}

export function useGetUserByEmail(params: { email: string }, enabled: boolean | undefined = true) {
  return useQuery({
    queryKey: ['get-user', params.email],
    queryFn: () => getUserByEmail(params.email),
    enabled,
  });
}

export const usePatchUser = () => {
  return useMutation('patchUser', {
    mutationFn: (userDetails: Partial<User>) => patchUser(userDetails),
  });
};

export const usePostForgottenPassword = () => {
  return useMutation('postForgottenPassword', {
    mutationFn: (resetDetails: { email: string; recaptcha: string; origin: string }) =>
      postUserForgottenPassword(resetDetails),
  });
};

export function useGetPasswordReset(resetId: string, options?: UseQueryOptions<{ email: string }, Error>) {
  return useQuery({
    queryKey: ['password-reset', resetId],
    queryFn: () => getResetId(resetId),
    ...{
      ...options,
      enabled: Boolean(resetId),
      retry: 0,
    },
  });
}
export const usePostResetPassword = () => {
  return useMutation('postForgottenPassword', {
    mutationFn: (resetDetails: { password: string; resetId: string }) =>
      postPasswordReset(resetDetails.resetId, { password: resetDetails.password }),
  });
};
