import { type NavigateOptions, useNavigate, createSearchParams, useSearchParams } from 'react-router-dom';

export function useNavigateWithSearchParams() {
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();

  function navigateWithSearchParams(
    path: string,
    options?: NavigateOptions,
    additionalSearchParams?: Record<string, string>,
    removeSearchParams?: string[],
  ) {
    const existingParams = Object.fromEntries(searchParams.entries());

    const filteredParams = Object.keys(existingParams).reduce((filteredObject, queryStringKey) => {
      if (!removeSearchParams?.includes(queryStringKey)) {
        return {
          ...filteredObject,
          [queryStringKey]: existingParams[queryStringKey],
        };
      }
      return filteredObject;
    }, {});

    const combinedParams = { ...filteredParams, ...additionalSearchParams };

    const newSearchParams = createSearchParams(combinedParams).toString();

    navigate({ pathname: path, search: newSearchParams }, options);
  }

  return navigateWithSearchParams;
}
