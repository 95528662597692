import axios, { type Axios, type Method } from 'axios';
import { fetchAuthSession } from 'aws-amplify/auth';

interface RequestProps<T> {
  endpoint: string;
  method: Method;
  body?: T;
  header?: Record<string, string>;
  addAuthHeaders?: boolean;
}

export async function request<T>({
  endpoint,
  method,
  body,
  header,
  addAuthHeaders = true,
}: RequestProps<T>): Promise<Axios> {
  try {
    let requestHeaders = header || {};

    if (addAuthHeaders) {
      const user = await fetchAuthSession();

      const accountId = localStorage.getItem('accountId');

      if (user.tokens?.accessToken) {
        requestHeaders = {
          Authorization: `Bearer ${user.tokens?.accessToken.toString()}`,
          ...header,
        };
      }

      if (accountId) {
        requestHeaders['x-account-id'] = accountId;
      }
    }

    return axios({
      method,
      url: endpoint,
      headers: { ...requestHeaders },
      data: body,
    });
  } finally {
  }
}
