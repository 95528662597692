import styled from 'styled-components';

export const DividerContainer = styled.div`
  display: flex;
  align-items: center;

  color: ${({ theme }) => theme.core.colors.greyscale.medium};

  hr {
    flex-grow: 1;

    height: 1px;

    background: ${({ theme }) => theme.core.colors.greyscale.medium};

    border: none;
  }

  span {
    padding: 0 ${({ theme }) => theme.core.sizing[2]}px;
  }
`;
