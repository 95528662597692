import { Button, FormField, Input, TelephoneInput } from '@pata-app/ui-form';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { Navigate, useNavigate } from 'react-router-dom';
import { FormContainer } from '../sign-up/signup.styled';
import { useGetUser, usePatchUser } from '../../queries/user.queries';
import { Loader } from '../../components/loader/loader';
import { useAuthContext } from '../../contexts/auth.context';
import { phoneNumberPattern } from '../../lib/helpers/validations';
import { queryClient } from '../../queries/query-client';
import { Icon } from '../../components/icon/icon';

export function PostAuth() {
  const { isAuthenticated } = useAuthContext();

  const { data: userDetails, isLoading } = useGetUser({ enabled: isAuthenticated ?? false });

  const { register, control, formState, getValues } = useForm({
    defaultValues: {
      firstName: userDetails?.firstName,
      lastName: userDetails?.lastName,
      phoneNumber: userDetails?.phoneNumber,
    },
  });

  const { mutateAsync: patchUserDetails } = usePatchUser();

  const navigate = useNavigate();

  const handleSubmit = async (e: React.SyntheticEvent) => {
    try {
      e.preventDefault();
      const values = getValues();

      await patchUserDetails({
        firstName: values.firstName,
        lastName: values.lastName,
        phoneNumber: values.phoneNumber,
      });

      await queryClient.invalidateQueries('user');

      navigate('/auth');
    } catch (e) {
      toast.error('Error updating user details');
    }
  };

  if (isLoading) return <Loader />;

  if (userDetails?.phoneNumber && userDetails.firstName && userDetails.lastName) {
    return <Navigate to="/auth" />;
  }

  if (!isAuthenticated) {
    return <Navigate to="/" />;
  }

  return (
    <FormContainer>
      <Icon size="150px" type="PataLogo" />
      <h1>
        <span>Enter user details</span>
      </h1>

      <form onSubmit={handleSubmit}>
        <FormField error={formState.errors.firstName?.message} label="First name">
          <Input
            {...register('firstName', {
              required: 'First name is required.',
            })}
            name="firstName"
          />
        </FormField>

        <FormField error={formState.errors.lastName?.message} label="Last name">
          <Input
            {...register('lastName', {
              required: 'Last name is required.',
            })}
            name="lastName"
          />
        </FormField>

        <FormField error={formState.errors.phoneNumber?.message} label="Mobile number">
          <Controller
            control={control}
            name="phoneNumber"
            render={({ field }) => {
              return <TelephoneInput {...field} countryCode="+44" hasError={Boolean(formState.errors.phoneNumber)} />;
            }}
            rules={{
              required: 'A phone number is required',
              pattern: {
                value: phoneNumberPattern,
                message: 'Valid phone number required',
              },
            }}
          />
        </FormField>

        <Button type="submit" disabled={!formState.isValid}>
          Continue
        </Button>
      </form>
    </FormContainer>
  );
}
