import React from 'react';
import { DateInput } from '@pata-app/ui-form';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';
import { useCalendarParams } from '../hooks/use-calendar-params';
import { CalendarViews } from '../../../../../types/appointments.types';
import { addDaysToDateString } from '../../../../../lib/helpers/add-days-to-date-string';
import { subtractDaysToDateString } from '../../../../../lib/helpers/substract-days-from-date-string';
import { Icon } from '../../../../../components/icon/icon';
import {
  CalendarButton,
  DateNavigatorContainer,
  NextButton,
  PreviousButton,
  TodayButton,
} from './date-navigator.styled';

export function DateNavigator() {
  const { t } = useTranslation();

  const { navigateDate, viewMode, calendarDate, getDateDisplay } = useCalendarParams();

  function navigatorToCurrentDay() {
    const currentDate = DateTime.now().toISODate();

    navigateDate(currentDate);
  }

  function navigatePrevious() {
    if (calendarDate) {
      const dayShift = viewMode === CalendarViews.DAY ? 1 : 7;

      const updatedDate = subtractDaysToDateString(calendarDate, dayShift);

      navigateDate(updatedDate ?? DateTime.now().toISODate());
    }
  }

  function navigateNext() {
    if (calendarDate) {
      const dayShift = viewMode === CalendarViews.DAY ? 1 : 7;

      const updatedDate = addDaysToDateString(calendarDate, dayShift);

      navigateDate(updatedDate);
    }
  }

  function handleCalendarDateChange(date: number) {
    navigateDate(date);
  }

  return (
    <DateNavigatorContainer>
      <PreviousButton onClick={navigatePrevious}>
        <Icon type="Arrow" />
      </PreviousButton>
      <DateInput
        fromYear={new Date().getFullYear() - 2}
        onChange={handleCalendarDateChange}
        to={new Date().getFullYear() + 2}
        value={calendarDate}
      >
        <CalendarButton>{getDateDisplay()}</CalendarButton>
      </DateInput>
      <TodayButton
        $highlight={DateTime.now().toISODate() === calendarDate}
        onClick={navigatorToCurrentDay}
        type="button"
      >
        {t('Today')}
      </TodayButton>
      <NextButton onClick={navigateNext}>
        <Icon type="Arrow" />
      </NextButton>
    </DateNavigatorContainer>
  );
}
