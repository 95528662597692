import React from 'react';
import { Outlet } from 'react-router-dom';
import { useAuthContext } from '../../../contexts/auth.context';
import { AuthedOnboard } from '../../authed-onboard/authed-onboard';
import { AuthLayoutContainer, AuthLayoutSection, AuthLayoutSide } from './auth-layout.styled';

export function AuthLayout({ postAuth }: { postAuth?: boolean }) {
  const { isAuthenticated } = useAuthContext();

  if (isAuthenticated === false || postAuth) {
    return (
      <AuthLayoutContainer>
        <AuthLayoutSide>
          <picture>
            <source src={`${process.env.CDN_BASE_URL}/images/image-lady-holding-cat.webp`} type="image/webp" />
            <img alt="Lady holding cat" src={`${process.env.CDN_BASE_URL}/images/image-lady-holding-cat.webp`} />
          </picture>
        </AuthLayoutSide>
        <AuthLayoutSection>
          <Outlet />
        </AuthLayoutSection>
      </AuthLayoutContainer>
    );
  }

  return <AuthedOnboard />;
}
