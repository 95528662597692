import { fetchAuthSession } from 'aws-amplify/auth';
import { Hub } from 'aws-amplify/utils';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const INVALID_GRANT_TYPE_MESSAGE = 'invalid_grant';

export function useAuth() {
  const navigate = useNavigate();
  const [isAuthenticated, setIsAuthenticated] = useState<boolean | null>(null);

  const checkUserAuth = useCallback(async () => {
    try {
      const user = await fetchAuthSession();

      setIsAuthenticated(Boolean(user.userSub));
    } catch (e) {
      setIsAuthenticated(false);
      console.error(e);
    }
  }, []);

  useEffect(() => {
    void checkUserAuth();

    Hub.listen('auth', ({ payload: { event, data } }) => {
      switch (event) {
        case 'signedIn':
          void checkUserAuth();
          break;
        case 'signedOut':
          void checkUserAuth();
          break;
        case 'signInWithRedirect_failure':
          if (data.message === INVALID_GRANT_TYPE_MESSAGE) navigate('/logout');
          break;
        default:
          break;
      }
    });
  }, [checkUserAuth, navigate]);

  return { isAuthenticated, checkUserAuth };
}
