import React, { type AnchorHTMLAttributes } from 'react';
import { useTranslation } from 'react-i18next';
import { Icon } from '../../../../../../icon/icon';
import { ItemTitle, MenuItemContainer } from './menu-item.styled';

interface MenuOptionProps extends AnchorHTMLAttributes<HTMLAnchorElement> {
  label: string;
  path: string;
  description?: string;
  icon?: string;
}

export function MenuItem({ label, path, icon, description, ...props }: MenuOptionProps): JSX.Element {
  const { t } = useTranslation();
  return (
    <MenuItemContainer to={`/${path}`} {...props}>
      {Boolean(icon) && <Icon size="30px" type={icon ?? ''} />}
      <p>
        <ItemTitle>{t(label, { ns: 'routes' })}</ItemTitle>
      </p>
    </MenuItemContainer>
  );
}
