export type TMethods = typeof methods;

export const methods = {
  GET: 'GET',
  POST: 'POST',
  PUT: 'PUT',
  HEAD: 'HEAD',
  PATCH: 'PATCH',
  DELETE: 'DELETE',
} as const;
