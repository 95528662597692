import React from 'react';
import { MobilePopUp } from '@pata-app/ui-pop-outs';
import { useTheme } from 'styled-components';
import { useMediaQuery } from 'styled-breakpoints/use-media-query';
import { useAccountHeaderContext } from '../../account-header.context';
import { AccountMenuOptions } from './account-menu-options/account-menu-options';
import { DesktopDropdownContainer } from './account-menu.styled';
import { AccountMenuOverview } from './account-menu-overview/account-menu-overview';

export function AccountMenu() {
  const { breakpoints } = useTheme();
  const { showAccountMenu, setShowAccountMenu } = useAccountHeaderContext();

  const isMd = useMediaQuery(breakpoints.up('sm'));

  if (isMd)
    return (
      <DesktopDropdownContainer isOpen={showAccountMenu}>
        <AccountMenuOverview />
        <AccountMenuOptions />
      </DesktopDropdownContainer>
    );

  return (
    <MobilePopUp handleTogglePopUp={setShowAccountMenu} isOpen={showAccountMenu}>
      <AccountMenuOverview />
      <AccountMenuOptions />
    </MobilePopUp>
  );
}
