import React from 'react';
import { Button, FormField, Input } from '@pata-app/ui-form';
import type { UseFormReturn } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useSocialLogin } from '../../hooks/use-social-login';
import { useGetUserByEmail } from '../../queries/user.queries';
import { emailPattern } from '../../lib/helpers/validations';
import { Divider } from '../divider/divider';
import { Icon } from '../icon/icon';

interface LoginFormProps {
  email: string;
  form: UseFormReturn<{ email: string }>;
  setCurrentStep: (step: 'login' | 'register') => void;
}

export function InitialEmailStep({ form, setCurrentStep, email }: LoginFormProps) {
  const {
    data: user,
    refetch: getUser,
    isLoading: isGetUserLoading,
  } = useGetUserByEmail({ email: encodeURIComponent(email) }, false);

  const handleSocialLogin = useSocialLogin();

  const onSocialLogin = () => {
    void handleSocialLogin('Google');
  };

  const {
    register,
    trigger,
    formState: { errors, isValid },
  } = form;

  const handleInitStep = async () => {
    try {
      const { data } = await getUser();

      if (!data) {
        setCurrentStep('register');
        return;
      }

      if (data.userType === 'standard') {
        setCurrentStep('login');
      }
    } catch (e) {
      const error = e as Error;
      toast.error(error.message);
    }
  };

  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();

    void trigger();

    if (isValid) {
      if (user?.userType === 'google') {
        void handleSocialLogin('Google');
        return;
      }

      void handleInitStep();
    }
  };

  const isGoogleUser = user?.userType === 'google';

  return (
    <>
      <form onSubmit={handleSubmit}>
        <FormField error={errors.email?.message} label="Email">
          <Input
            disabled={isGoogleUser}
            {...register('email', {
              required: 'Email is required',
              setValueAs: (value: string) => value.trim(),
              pattern: {
                message: 'Not a valid email',
                value: emailPattern,
              },
            })}
            name="email"
          />
        </FormField>

        {isGoogleUser ? (
          <Button buttonType="secondary" type="submit">
            <Icon type="Google" />
            Continue with Google
          </Button>
        ) : null}

        {!isGoogleUser && (
          <Button isLoading={isGetUserLoading} type="submit">
            Continue
          </Button>
        )}
      </form>

      {!isGoogleUser && (
        <>
          <Divider>or</Divider>
          <Button buttonType="secondary" onClick={onSocialLogin}>
            <Icon type="Google" />
            Continue with Google
          </Button>
        </>
      )}
    </>
  );
}
