import { useMemo } from 'react';
import { loadConnectAndInitialize } from '@stripe/connect-js';
import { useGetPaymentAccount } from '../queries/payments.queries';

export function useStripeConnectInstance() {
  const { data: paymentAccountData, isLoading } = useGetPaymentAccount();

  const stripeConnectInstance = useMemo(() => {
    if (paymentAccountData) {
      const fetchClientSecret = async () => {
        return paymentAccountData.clientSecret;
      };

      return loadConnectAndInitialize({
        publishableKey: process.env.STRIPE_KEY,
        fetchClientSecret: fetchClientSecret,
        appearance: {
          variables: {
            fontFamily: 'sans-serif',
            colorPrimary: '#26948f',
            buttonPrimaryColorBackground: '#2e2d2c',
            buttonSecondaryColorBackground: '#ffffff',
            buttonSecondaryColorBorder: '#abb2b2',
            buttonSecondaryColorText: '#2e2d2c',
            buttonBorderRadius: '8px',
            formBorderRadius: '8px',
          },
        },
      });
    }

    return null;
  }, [paymentAccountData]);

  return { stripeConnectInstance, isLoadingInstance: isLoading };
}
