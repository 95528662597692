import { type UseQueryResult, useQuery, useMutation, type UseQueryOptions } from 'react-query';
import {
  getPaymentAccount,
  putPaymentProtection,
  postPaymentAccount,
  deletePaymentProtection,
} from '../api/payments.api';
import { Terminal, type PaymentAccount } from '../types/payments.types';
import {
  cancelPaymentTerminal,
  deletePaymentTerminal,
  getTerminal,
  getTerminals,
  patchTerminal,
  postTerminal,
  putTerminal,
} from '../api/terminals.api';
import { queryClient } from './query-client';

export function useGetTerminals(options?: UseQueryOptions<Terminal[], Error>): UseQueryResult<PaymentAccount> {
  return useQuery<Terminal[], Error>('paymentTerminals', () => getTerminals(), {
    staleTime: 100000,
    ...options,
  });
}

export function useGetTerminal(
  id: string,
  options?: UseQueryOptions<PaymentAccount, Error>,
): UseQueryResult<PaymentAccount> {
  return useQuery<PaymentAccount, Error>(['paymentTerminal', id], () => getTerminal(id), {
    staleTime: 100000,
    ...options,
  });
}

export const usePostPaymentTerminal = () => {
  return useMutation('postPaymentAccount', {
    mutationFn: (terminal: Record<string, string>) => postTerminal(terminal),
  });
};

export const usePatchPaymentTerminal = () => {
  return useMutation('putPaymentAccount', {
    mutationFn: ({
      terminalId,
      terminalDetails,
    }: {
      terminalId: string;
      terminalDetails: {
        status: 'inactive' | 'active';
        name: string;
        workplaces: string[];
      };
    }) => patchTerminal(terminalId, terminalDetails),
  });
};

export const useDeletePaymentTerminal = () => {
  return useMutation('deletePaymentTerminal', {
    mutationFn: (id: string) => deletePaymentTerminal(id),
  });
};

export const prefetchTerminals = async () => {
  await queryClient.prefetchQuery({
    queryKey: 'paymentTerminals',
    queryFn: () => getTerminals(),
  });
};
