// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/.pnpm/css-loader@6.10.0_webpack@5.90.3/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/.pnpm/css-loader@6.10.0_webpack@5.90.3/node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/.pnpm/css-loader@6.10.0_webpack@5.90.3/node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../assets/fonts/OpenSans-Light.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../assets/fonts/OpenSans-Regular.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("../assets/fonts/OpenSans-SemiBold.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("../assets/fonts/Jost-Variable.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
    font-family: 'Open Sans';
    src: local('Open Sans'), url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format('truetype');
    font-weight: 100;
    font-style: normal;
    font-display: swap;

}

@font-face {
    font-family: 'Open Sans';
    src: local('Open Sans'), url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format('truetype');
    font-weight: 400;
    font-style: normal;
    font-display: swap;

}

@font-face {
    font-family: 'Open Sans';
    src: local('Open Sans'), url(${___CSS_LOADER_URL_REPLACEMENT_2___}) format('truetype');
    font-weight: 700;
    font-style: normal;
    font-display: swap;

}

/* @font-face {
    font-family: 'Jost';
    src: local('Jost'), url(../assets/fonts/Jost-Regular.ttf) format('truetype');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Jost';
    src: local('Jost'), url(../assets/fonts/Jost-SemiBold.ttf) format('truetype');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Jost';
    src: local('Jost'), url(../assets/fonts/Jost-Bold.ttf) format('truetype');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
} */

@font-face {
    font-family: 'Jost';
    src: local('Jost'), url(${___CSS_LOADER_URL_REPLACEMENT_3___}) format('truetype');
    font-display: swap;
}`, "",{"version":3,"sources":["webpack://./src/styles/fonts.css"],"names":[],"mappings":"AAAA;IACI,wBAAwB;IACxB,mFAAmF;IACnF,gBAAgB;IAChB,kBAAkB;IAClB,kBAAkB;;AAEtB;;AAEA;IACI,wBAAwB;IACxB,mFAAqF;IACrF,gBAAgB;IAChB,kBAAkB;IAClB,kBAAkB;;AAEtB;;AAEA;IACI,wBAAwB;IACxB,mFAAsF;IACtF,gBAAgB;IAChB,kBAAkB;IAClB,kBAAkB;;AAEtB;;AAEA;;;;;;;;;;;;;;;;;;;;;;GAsBG;;AAEH;IACI,mBAAmB;IACnB,8EAA6E;IAC7E,kBAAkB;AACtB","sourcesContent":["@font-face {\n    font-family: 'Open Sans';\n    src: local('Open Sans'), url(../assets/fonts/OpenSans-Light.ttf) format('truetype');\n    font-weight: 100;\n    font-style: normal;\n    font-display: swap;\n\n}\n\n@font-face {\n    font-family: 'Open Sans';\n    src: local('Open Sans'), url(../assets/fonts/OpenSans-Regular.ttf) format('truetype');\n    font-weight: 400;\n    font-style: normal;\n    font-display: swap;\n\n}\n\n@font-face {\n    font-family: 'Open Sans';\n    src: local('Open Sans'), url(../assets/fonts/OpenSans-SemiBold.ttf) format('truetype');\n    font-weight: 700;\n    font-style: normal;\n    font-display: swap;\n\n}\n\n/* @font-face {\n    font-family: 'Jost';\n    src: local('Jost'), url(../assets/fonts/Jost-Regular.ttf) format('truetype');\n    font-weight: 400;\n    font-style: normal;\n    font-display: swap;\n}\n\n@font-face {\n    font-family: 'Jost';\n    src: local('Jost'), url(../assets/fonts/Jost-SemiBold.ttf) format('truetype');\n    font-weight: 600;\n    font-style: normal;\n    font-display: swap;\n}\n\n@font-face {\n    font-family: 'Jost';\n    src: local('Jost'), url(../assets/fonts/Jost-Bold.ttf) format('truetype');\n    font-weight: 700;\n    font-style: normal;\n    font-display: swap;\n} */\n\n@font-face {\n    font-family: 'Jost';\n    src: local('Jost'), url(../assets/fonts/Jost-Variable.ttf) format('truetype');\n    font-display: swap;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
