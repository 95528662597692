import type { PropsWithChildren } from 'react';
import React from 'react';
import { Navigate } from 'react-router-dom';
import { useGetUser } from '../../../../../queries/user.queries';
import { useAuthContext } from '../../../../../contexts/auth.context';

export function OnboardingRoute({ children }: PropsWithChildren) {
  const { data: userData, isLoading } = useGetUser();
  const { isAuthenticated } = useAuthContext();

  if (!isAuthenticated) return <Navigate to="/auth" />;

  if (isLoading) return null;

  if (!userData?.phoneNumber) {
    return <Navigate to="/post-auth/details" />;
  }

  if (userData?.accounts?.length) {
    return <Navigate to="/" />;
  }

  return children;
}
