import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, FormField, FormLayout, Input } from '@pata-app/ui-form';
import { useLogin } from '../../hooks/use-login';
import { ForgottenPasswordLink } from '../../pages/sign-up/signup.styled';

interface LoginStepProps {
  email: string;
}

export function LoginStep({ email }: LoginStepProps) {
  const { t } = useTranslation('auth');

  const [isLoading, setIsLoading] = useState(false);
  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');

  const handleLogin = useLogin();

  const handleSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault();

    if (!password) {
      setPasswordError('Password is required');

      return;
    }

    setIsLoading(true);
    try {
      await handleLogin(email, password);
      setPassword('');
      setPasswordError('');
    } catch (e) {
      const error = e as Error;
      if (error.message === 'Incorrect username or password.') {
        setPasswordError('invalid_password');
        return;
      }

      if (error.message === 'Password attempts exceeded') {
        setPasswordError('exceeded_password_attempts');
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <form onSubmit={(e) => void handleSubmit(e)}>
      <FormLayout columnConfig={{ md: 1 }}>
        <FormField label="Email">
          <Input disabled name="email" value={email} />
        </FormField>

        <FormField error={t(passwordError)} label="Password">
          <Input
            autoComplete="password"
            autoFocus
            hasError={Boolean(passwordError)}
            name="password"
            onChange={(e) => {
              setPassword(e.target.value);

              if (passwordError && e.target.value) {
                setPasswordError('');
              }
            }}
            type="password"
            value={password}
          />
        </FormField>
        <ForgottenPasswordLink to={`/forgotten-password?email=${email}`}>Forgotten Password</ForgottenPasswordLink>
        <Button isLoading={isLoading} type="submit">
          Login
        </Button>
      </FormLayout>
    </form>
  );
}
