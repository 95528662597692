import styled from 'styled-components';
import { AccountIconContainer } from '../../account-icon/account-icon.styled';

export const AccountMenuOverviewContainer = styled.div``;

export const UserOverviewContainer = styled.div`
  display: grid;
  grid-template-columns: min-content auto;
  grid-template-rows: auto auto;
  align-content: center;
  gap: ${({ theme }) => theme.core.sizing[1]}px;

  padding: ${({ theme }) => theme.core.sizing[2]}px;

  background: ${({ theme }) => theme.core.colors.greyscale.light};
  border-radius: ${({ theme }) => theme.core.border.container.radius}px;

  ${AccountIconContainer} {
    grid-row: 1 / span 2;
    grid-column: 1 / span 1;
  }

  span {
    align-self: center;

    font-weight: ${({ theme }) => theme.core.fontWeight.semiBold};

    &:first-of-type {
      grid-row: 1 / span 1;
      grid-column: 2 / span 1;
    }
  }
`;
