export enum ColourNames {
  '#d3e8e4' = 'EDGE_WATER',
  '#f8d380' = 'SWEET_CORN',
  '#f8bd7c' = 'RAJAH',
  '#ffbeb8' = 'SOFT_PINK',
  '#83b394' = 'BAY_LEAF',
  '#d7b7d9' = 'PRELUDE',
  '#b4d5e3' = 'POWDER_BLUE',
  '#73cac8' = 'DOWNY',
}

export enum ColourNamesCalenderEvents {
  '#d3e8e4' = 'EDGE_WATER',
  '#f8d380' = 'SWEET_CORN',
  '#f8bd7c' = 'RAJAH',
  '#ffbeb8' = 'SOFT_PINK',
  '#83b394' = 'BAY_LEAF',
  '#d7b7d9' = 'PRELUDE',
  '#b4d5e3' = 'POWDER_BLUE',
  '#73cac8' = 'DOWNY',
  '#cccccc' = 'LIGHT_GREY',
}

export enum ColourNamesFormatted {
  '#d3e8e4' = 'Edge Water',
  '#f8d380' = 'Sweetcorn',
  '#f8bd7c' = 'Sand',
  '#ffbeb8' = 'Soft Pink',
  '#83b394' = 'Bay Leaf',
  '#d7b7d9' = 'Predule',
  '#b4d5e3' = 'Powder Blue',
  '#73cac8' = 'Downy',
}
