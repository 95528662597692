import { Amplify, type ResourcesConfig } from 'aws-amplify';
import { useState, useEffect } from 'react';

const hostname = new URL(window.location.href).origin;

export function useAmplifySetup() {
  const [isAmplifyConfigured, setIsAmplifyConfigured] = useState(false);

  useEffect(() => {
    function setupAmplify() {
      try {
        const amplifyConfig: ResourcesConfig = {
          Auth: {
            Cognito: {
              userPoolId: process.env.AWS_COGNITO_USER_POOL_ID || '',
              userPoolClientId: process.env.AWS_COGNITO_USER_POOL_WEB_CLIENT_ID || '',
              identityPoolId: process.env.AWS_COGNITO_IDENTITY_POOL_ID || '',
              loginWith: {
                oauth: {
                  domain: process.env.AWS_COGNITO_DOMAIN || '',
                  scopes: ['email', 'profile', 'openid'],
                  redirectSignIn: [`${hostname}/auth`],
                  redirectSignOut: [`${hostname}/login/`],
                  responseType: 'code',
                },
                username: true,
              },
            },
          },
        };

        Amplify.configure(amplifyConfig);

        setIsAmplifyConfigured(true);
      } catch (err) {
        console.error(err);
      }
    }

    setupAmplify();
  }, []);

  return { isAmplifyConfigured };
}
