import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const LinkButtonContainer = styled(Link)`
  text-decoration: none;

  align-self: flex-start;

  button {
    min-width: 100px;
  }
`;
