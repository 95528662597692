import { type UseQueryResult, useQuery, useMutation, type UseQueryOptions, type QueryKey } from 'react-query';
import {
  type Membership,
  type MembershipSearch,
  type Service,
  type ServiceCategory,
  type ServiceCategorySearch,
  type ServicesSearch,
} from '../types/catalogue.types';
import {
  deleteMembership,
  deleteService,
  deleteServiceCategory,
  getMembership,
  getMemberships,
  getService,
  getServiceCategories,
  getServiceCategory,
  getServices,
  patchMembership,
  patchService,
  patchServiceCategory,
  postMembership,
  postService,
  postServiceCategory,
} from '../api/catalogue.api';
import { useGetUser } from './user.queries';
import { queryClient } from './query-client';

export function useGetServices(
  queryKey: QueryKey,
  queryString?: URLSearchParams,
  options?: UseQueryOptions<ServicesSearch, Error>,
): UseQueryResult<ServicesSearch> {
  const { data } = useGetUser();

  const accountId = data?.accounts?.[0];

  return useQuery<ServicesSearch, Error>(queryKey, () => getServices(queryString), {
    enabled: Boolean(accountId),
    ...options,
  });
}

export function useGetService(
  serviceId: string | null,
  options?: UseQueryOptions<Service, Error>,
): UseQueryResult<Service> {
  return useQuery<Service, Error>(['service', serviceId], () => getService(serviceId as string), {
    staleTime: 100000,
    enabled: Boolean(serviceId),
    ...options,
  });
}

export const usePostService = () => {
  return useMutation('postService', {
    mutationFn: (serviceDetails: Partial<Service>) => postService(serviceDetails),
  });
};

export const usePatchService = () => {
  return useMutation('patchService', {
    mutationFn: (data: { id: string; serviceDetails: Partial<Service> }) => patchService(data.id, data.serviceDetails),
  });
};

export const useDeleteService = () => {
  return useMutation('deleteService', {
    mutationFn: (id: string) => deleteService(id),
  });
};

export const prefetchServices = async () => {
  await queryClient.prefetchQuery({
    queryKey: ['services'],
    queryFn: getServices,
  });
};

export function useGetServiceCategories(
  queryKey: QueryKey,
  queryString?: string,
  options?: UseQueryOptions<ServiceCategorySearch, Error>,
): UseQueryResult<ServiceCategorySearch> {
  const { data } = useGetUser();

  const accountId = data?.accounts?.[0];

  return useQuery<ServiceCategorySearch, Error>(queryKey, () => getServiceCategories(queryString), {
    enabled: Boolean(accountId),
    ...options,
  });
}

export function useGetServiceCategory(
  serviceCategoryId: string | null,
  options?: UseQueryOptions<ServiceCategory, Error>,
): UseQueryResult<ServiceCategory> {
  return useQuery<ServiceCategory, Error>(
    ['serviceCategory', serviceCategoryId],
    () => getServiceCategory(serviceCategoryId as string),
    {
      staleTime: 100000,
      enabled: Boolean(serviceCategoryId),
      ...options,
    },
  );
}

export const usePostServiceCategory = () => {
  return useMutation('postServiceCategory', {
    mutationFn: (serviceCategoryDetails: Partial<ServiceCategory>) => postServiceCategory(serviceCategoryDetails),
  });
};

export const usePatchServiceCategory = () => {
  return useMutation('patchServiceCategory', {
    mutationFn: (data: { id: string; serviceCategoryDetails: Partial<ServiceCategory> }) =>
      patchServiceCategory(data.id, data.serviceCategoryDetails),
  });
};

export const useDeleteServiceCategory = () => {
  return useMutation('deleteServiceCategory', {
    mutationFn: (id: string) => deleteServiceCategory(id),
  });
};

export function useGetMemberships(
  queryKey: QueryKey,
  queryString?: URLSearchParams,
  options?: UseQueryOptions<MembershipSearch, Error>,
): UseQueryResult<MembershipSearch> {
  const { data } = useGetUser();

  const accountId = data?.accounts[0];

  return useQuery<MembershipSearch, Error>(queryKey, () => getMemberships(queryString), {
    enabled: Boolean(accountId),
    ...options,
  });
}

export function useGetMembership(
  membershipId: string | null,
  options?: UseQueryOptions<Membership, Error>,
): UseQueryResult<Membership> {
  return useQuery<Membership, Error>(['membership', membershipId], () => getMembership(membershipId as string), {
    staleTime: 100000,
    enabled: Boolean(membershipId),
    ...options,
  });
}

export const usePostMembership = () => {
  return useMutation('postMembership', {
    mutationFn: (membershipDetails: Partial<Membership>) => postMembership(membershipDetails),
  });
};

export const usePatchMembership = () => {
  return useMutation('patchMembership', {
    mutationFn: (data: { id: string; membershipDetails: Partial<Membership> }) =>
      patchMembership(data.id, data.membershipDetails),
  });
};

export const useDeleteMembership = () => {
  return useMutation('deleteMembership', {
    mutationFn: (id: string) => deleteMembership(id),
  });
};

export const prefetchCatalogueServices = async () => {
  await queryClient.prefetchQuery({
    queryKey: ['services'],
    queryFn: () => getServices(),
  });
};
