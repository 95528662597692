import React from 'react';
import { ConnectComponentsProvider } from '@stripe/react-connect-js';
import { useStripeConnectInstance } from '../hooks/use-stripe-connect-instance';

interface StripeConnectProviderProps {
  children: React.ReactNode;
}

export function StripeConnectProvider({ children }: StripeConnectProviderProps) {
  const { stripeConnectInstance, isLoadingInstance } = useStripeConnectInstance();

  if (stripeConnectInstance && !isLoadingInstance) {
    return <ConnectComponentsProvider connectInstance={stripeConnectInstance}>{children}</ConnectComponentsProvider>;
  }
  return <>{children}</>;
}
