import styled from 'styled-components';

export const MainContentContainer = styled.main`
  display: grid;
  align-content: flex-start;

  padding: ${({ theme }) => theme.core.sizing[4]}px;

  overflow-y: auto;

  width: 100%;
`;
