import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import { InitialEmailStep } from '../../components/sign-in-steps/email-step';
import { LoginStep } from '../../components/sign-in-steps/login-step';
import { RegisterStep } from '../../components/sign-in-steps/register-step';
import { Icon } from '../../components/icon/icon';
import { AuthFormContainer, AuthHeaderContainer, FormContainer } from './signup.styled';

type StepId = 'init' | 'login' | 'register';
export type LoginModalOpenType = 'login' | 'register' | undefined;

interface EmailForm {
  email: string;
}

export function Signup() {
  const location = useLocation();

  const isSignup = location.pathname.includes('sign-up');

  const [currentStep, setCurrentStep] = useState<StepId>('init');

  const emailForm = useForm<EmailForm>({
    defaultValues: {
      email: '',
    },
    mode: 'onBlur',
  });

  const email = emailForm.watch('email');

  const showLoginHeader = isSignup || currentStep === 'register';

  return (
    <FormContainer>
      <Icon size="150px" type="PataLogo" />
      <AuthHeaderContainer>
        <h1>
          <span>{showLoginHeader ? 'Sign up to' : 'Log in to'}</span>
          <span>Pata Partners</span>
        </h1>

        <p>
          {showLoginHeader
            ? 'Create an account to start managing your business with Pata'
            : 'Login to access your Pata Partners account'}
        </p>
      </AuthHeaderContainer>

      <AuthFormContainer>
        {
          {
            init: (
              <InitialEmailStep
                email={email}
                form={emailForm}
                setCurrentStep={(step) => {
                  setCurrentStep(step);
                }}
              />
            ),
            login: <LoginStep email={email} />,
            register: <RegisterStep email={email} />,
          }[currentStep]
        }
      </AuthFormContainer>
    </FormContainer>
  );
}

export default Signup;
