import React, { type RefObject } from 'react';
import { useScrollBarPosition } from '@pata-app/hooks';
import { Header } from '../components/header/header';
import { MobileNavigaion } from '../components/navigation/mobile-navigation/mobile-navigation';
import { MobileSubMenu } from '../components/navigation/mobile-navigation/components/mobile-sub-menu/mobile-sub-menu';
import { useMenuPath } from '../components/navigation/hooks/use-menu-path';
import { AppScrollContext } from '../contexts/app-scroll.context';
import { MobileContentContainer, MobileLayoutContainer, MobileMainContent } from './mobile-layout.styled';

interface MobileLayoutProps {
  children: React.ReactNode;
}

export function MobileLayout({ children }: MobileLayoutProps) {
  const { hasSelectedRoute } = useMenuPath();
  const [ref, top] = useScrollBarPosition();

  return (
    <AppScrollContext.Provider value={{ scrollFromTop: top }}>
      {/* <MobileLayoutContainer ref={ref as RefObject<HTMLDivElement>}> */}
      <Header />

      <MobileMainContent ref={ref as RefObject<HTMLDivElement>}>
        <MobileSubMenu />
        <MobileContentContainer>{Boolean(hasSelectedRoute) && <>{children}</>}</MobileContentContainer>
      </MobileMainContent>
      <MobileNavigaion />
      {/* </MobileLayoutContainer> */}
    </AppScrollContext.Provider>
  );
}
