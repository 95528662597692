import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 60px;

  width: 100%;
  height: 100%;

  padding-bottom: 40px;

  > form {
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.core.sizing[1]}px;
  }

  h1 {
    display: flex;
    flex-direction: column;

    margin: 0;

    font-family: ${({ theme }) => theme.core.fontFamily.jost};
    font-size: ${({ theme }) => theme.core.fontSizes.xxxl}px;

    br {
      height: 16px;
    }
  }

  h2 {
    margin: 0;

    font-size: ${({ theme }) => theme.core.fontSizes.m}px;
    font-weight: ${({ theme }) => theme.core.fontWeight.medium};
  }

  h3 {
    margin: 0;

    font-size: ${({ theme }) => theme.core.fontSizes.xl}px;
    font-weight: ${({ theme }) => theme.core.fontWeight.medium};
  }

  p {
    margin: 0;
    margin-top: ${({ theme }) => theme.core.sizing[0]}px;

    font-size: ${({ theme }) => theme.core.fontSizes.m}px;
    color: ${({ theme }) => theme.core.colors.greyscale.dark};
  }

  button {
    width: 100%;

    div {
      display: flex;
      align-items: center;
      gap: ${({ theme }) => theme.core.sizing[1]}px;
    }
  }

  > svg {
    height: 60px;
    min-height: 60px;

    color: ${({ theme }) => theme.core.colors.primary.green.medium};

    align-self: flex-start;
  }

  hr {
    width: 100%;
    color: ${({ theme }) => theme.core.colors.greyscale.light};
  }
`;

export const AuthFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.core.sizing[2]}px;

  padding-bottom: 60px;

  form {
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.core.sizing[2]}px;
  }
`;

export const AuthHeaderContainer = styled.div`
  p {
    margin-top: ${({ theme }) => theme.core.sizing[1]}px;
  }
`;

export const ForgottenPasswordLink = styled(Link)`
  text-align: end;

  color: ${({ theme }) => theme.core.colors.primary.green.medium};
`;
