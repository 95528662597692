import { type Method } from 'axios';
import { fetchAuthSession } from 'aws-amplify/auth';
import { methods, request } from '../lib';
import { type User } from '../types/user.types';
import { endpoints } from './endpoints';

export interface GetUserResponse {
  email: string;
  userType: 'google' | 'standard';
}

export interface GetResetResponse {
  email: string;
}

export async function getUser(): any {
  const user = await fetchAuthSession();

  const userId = user.tokens?.idToken?.payload.sub ?? '';

  const data = await request({
    endpoint: endpoints.users.id(userId),
    method: methods.GET as Method,
  }); 

  return data.data.data;
}

export async function getUserByEmail(email: string): Promise<GetUserResponse> {
  const response = await request<GetUserResponse>({
    endpoint: endpoints.userByEmail(email),
    method: methods.GET,
    addAuthHeaders: false,
  });

  return response.data.data;
}

export async function patchUser(body: Partial<User>): any {
  const user = await fetchAuthSession();

  const userId = user.tokens?.idToken?.payload.sub ?? '';

  await request({
    endpoint: endpoints.users.id(userId),
    method: methods.PATCH as Method,
    body,
  });
}

export async function postUserForgottenPassword(
  body: Partial<{ email: string; recaptcha: string; origin: string }>,
): any {
  await request({
    endpoint: endpoints.users.auth.resets.all,
    method: methods.POST as Method,
    body: { email: body.email, origin: body.origin },
    header: {
      'x-recaptcha-secret': body.recaptcha,
    },
  });
}

export async function getResetId(resetId: string): Promise<GetResetResponse> {
  const response = await request<GetResetResponse>({
    endpoint: endpoints.users.auth.resets.id(resetId),
    method: methods.GET,
  });

  return response.data.data;
}

export async function postPasswordReset(resetId: string, body: { password: string }): any {
  const response = await request<{ password: string }>({
    endpoint: endpoints.users.auth.resets.id(resetId),
    method: methods.PUT,
    body,
  });

  return response.data.data;
}
