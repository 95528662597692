import React, { useState } from 'react';
import { navigationConfig } from '../routes.config';
import { Icon } from '../../../../../icon/icon';
import { useMenuPath } from '../hooks/use-menu-path';
import { SubMenu } from './components/sub-menu/sub-menu';
import {
  MainNavigationContainer,
  SideNavigationContainer,
  SideNavigationItem,
  SubMenuContainer,
  SubMenuToggleButton,
} from './side-navigation.styled';

export function SideNavigation() {
  const [showSubMenu, setShowSubMenu] = useState(true);

  const { currentSubMenuConfig, currentPrimaryRoute } = useMenuPath();

  const handleSubMenuToggle = () => {
    setShowSubMenu((currentToggle) => !currentToggle);
  };

  return (
    <SideNavigationContainer $hasSubMenu={Boolean(currentSubMenuConfig)} $showSubMenu={showSubMenu}>
      <MainNavigationContainer>
        {navigationConfig.map((navigationItem) => (
          <SideNavigationItem
            isSelected={currentPrimaryRoute === navigationItem.path}
            key={navigationItem.path}
            to={`/${navigationItem.path}`}
          >
            <Icon size="30px" type={navigationItem.icon} />
          </SideNavigationItem>
        ))}
      </MainNavigationContainer>

      {Boolean(currentSubMenuConfig) && (
        <SubMenuContainer>
          <SubMenuToggleButton onClick={handleSubMenuToggle}>
            <Icon type={showSubMenu ? 'Close' : 'Arrow'} />
          </SubMenuToggleButton>
          <SubMenu />
        </SubMenuContainer>
      )}
    </SideNavigationContainer>
  );
}
