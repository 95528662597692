import { type Method } from 'axios';
import { type Account } from '../types/account.types';
import { methods, request } from '../lib';
import { endpoints } from './endpoints';

export async function getAccount(accountId: string): Promise<Account> {
  const data = await request({
    endpoint: endpoints.accounts.id.base(accountId),
    method: methods.GET as Method,
  });

  return data.data.data;
}

export async function patchAccount(accountId: string, body: Partial<Account>): Promise<void> {
  await request({
    endpoint: endpoints.accounts.id.base(accountId),
    method: methods.PATCH as Method,
    body,
  });
}
