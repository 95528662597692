import { Link } from 'react-router-dom';
import styled from 'styled-components';

interface SectionHeaderContainerProps {
  $showTitle: boolean;
  $isMainHeader: boolean;
}

export const SectionHeaderContainer = styled.div<SectionHeaderContainerProps>`
  display: flex;
  gap: ${({ theme }) => theme.core.sizing[1]}px;
  justify-content: space-between;

  h1 {
    margin: 0;

    font-size: ${({ theme, $isMainHeader }) => ($isMainHeader ? theme.core.fontSizes.m : theme.core.fontSizes.xxl)}px;

    opacity: ${({ $showTitle }) => ($showTitle ? 1 : 0)};

    transition: opacity 0.2s ease-in-out;
  }

  ${({ theme }) => theme.breakpoints.up('sm')} {
    align-items: center;

    height: auto;

    margin-top: 20px;

    button {
      min-width: 150px;
    }
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.core.sizing[1]}px;

  h1 {
    font-family: ${({ theme }) => theme.core.fontFamily.jost};
  }

  p {
    display: none;
  }

  ${({ theme }) => theme.breakpoints.up('sm')} {
    p {
      display: flex;

      font-family: 'Jost';
      font-weight: 400;

      color: ${({ theme }) => theme.core.colors.greyscale.dark};
    }
  }
`;

export const BackLink = styled(Link)`
  display: flex;
  align-items: center;

  svg {
    fill: ${({ theme }) => theme.core.colors.greyscale.white};
  }
`;
