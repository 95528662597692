import { signInWithRedirect } from 'aws-amplify/auth';
import { useCallback } from 'react';

type TSocialProviders = 'Google' | 'Apple';

export function useSocialLogin() {
  const handleSocalLogin = useCallback(async (provider: TSocialProviders) => {
    try {
      await signInWithRedirect({
        provider: {
          custom: provider,
        },
      });
    } catch (error) {
      throw new Error(error);
    }
  }, []);

  return handleSocalLogin;
}
