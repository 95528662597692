import React, { useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { useLogout } from '../../hooks/use-logout';
import { useAuth } from '../../hooks/use-auth';

export function Logout() {
  const handleLogout = useLogout();
  const { isAuthenticated } = useAuth();

  useEffect(() => {
    async function signOut() {
      try {
        await handleLogout();
      } catch {}
    }

    void signOut();
  }, []);

  if (isAuthenticated === false) {
    return <Navigate to="/login" />;
  }

  return null;
}
