import { type AxiosError } from 'axios';
import { MutationCache, QueryCache, QueryClient } from 'react-query';
import { toast } from 'react-toastify';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
  queryCache: new QueryCache({
    onError: (_: AxiosError, query) => {
      if (query.state.data !== undefined) {
        toast.error('Something went wrong');
      }
    },
  }),
  mutationCache: new MutationCache({
    onError: (error: AxiosError<{ error: { message: string } }>) => {
      let errorMessage = 'Something went Wrong';

      if (error.response?.status === 400) {
        errorMessage = `Something went wrong: ${error.response.data.error.message}`;
      }

      toast.error(errorMessage);
    },
  }),
});
