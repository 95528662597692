import React from 'react';
import { AuthFormContainerStyled } from './auth-form.container.styled';

interface AuthFormContainerProps {
  children: React.ReactNode;
}

export function AuthFormContainer({ children }: AuthFormContainerProps) {
  return <AuthFormContainerStyled>{children}</AuthFormContainerStyled>;
}
