import React from 'react';
import { type EventProps } from 'react-big-calendar';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { formatSeconds } from '../../../../lib/helpers/format-seconds';
import { type AppointmentStatus, type CalendarEvent } from '../../../../types/appointments.types';
import { BlockedTimeContainer, CustomEventContainer } from './custom-event.styled';
import { removeDuplicateWords } from '../../../../lib/helpers/remove-duplicated-services';

export const AppointmentStatusIndicatorContainer = styled.span`
  position: absolute;

  display: flex;
  align-items: center;
  justify-content: center;
  align-self: flex-end;

  top: 4px;

  height: 18px;

  padding: 0 ${({ theme }) => theme.core.sizing[1]}px;

  font-size: ${({ theme }) => theme.core.fontSizes.s}px;

  color: ${({ theme }) => theme.core.colors.greyscale.white};
  border: 1px solid ${({ theme }) => theme.core.colors.greyscale.white};

  border-radius: 9px;
`;

interface AppointmentStatusIndicatorProps {
  status: AppointmentStatus;
}

export function AppointmentStatusIndicator({ status }: AppointmentStatusIndicatorProps) {
  const { t } = useTranslation('common');

  return <AppointmentStatusIndicatorContainer>{t(status)}</AppointmentStatusIndicatorContainer>;
}

export function CustomEvent({ event }: EventProps<CalendarEvent>) {
  return (
    <CustomEventContainer>
      <strong>{event.title}</strong>
      <span>{removeDuplicateWords(event.description)}</span>
      {Boolean(event.blockedTimeBefore || event.blockedTimeAfter) && (
        <BlockedTimeContainer>
          <span>Blocked time: </span>
          {Boolean(event.blockedTimeBefore) && (
            <span>{`${formatSeconds(event.blockedTimeBefore, undefined, true)} before`}</span>
          )}
          {Boolean(event.blockedTimeAfter && event.blockedTimeBefore) && <span>|</span>}
          {Boolean(event.blockedTimeAfter) && (
            <span>{`${formatSeconds(event.blockedTimeAfter, undefined, true)} after`}</span>
          )}
        </BlockedTimeContainer>
      )}

      {Boolean(event.status) && <AppointmentStatusIndicator status={event.status} />}
    </CustomEventContainer>
  );
}
