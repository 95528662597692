import styled from 'styled-components';

export const MenuContainer = styled.div`
  background: ${({ theme }) => theme.core.colors.greyscale.white};

  @media ${({ theme }) => theme.core.breakpoints.s} {
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.core.sizing[2]}px;
  }
`;

export const MenuTitle = styled.span`
  display: none;

  @media ${({ theme }) => theme.core.breakpoints.s} {
    display: flex;

    padding-right: ${({ theme }) => theme.core.sizing[1]}px;

    font-weight: ${({ theme }) => theme.core.fontWeight.medium};
  }
`;

export const MenuItemsContainer = styled.ul`
  display: flex;
  gap: ${({ theme }) => theme.core.sizing[4]}px;

  overflow-x: auto;

  padding: 0;
  margin: 0;

  li {
    padding: 0;
    margin: 0;
    list-style: none;
  }

  @media ${({ theme }) => theme.core.breakpoints.s} {
    flex-direction: column;
    gap: 12px;

    overflow-x: visible;
  }
`;
