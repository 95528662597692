import { useSearchParams } from 'react-router-dom';
import { format } from 'date-fns';
import { getWeekRange } from '../../../../../lib/helpers/get-work-range';
import { useSetSearchParamsAndLocalStorage } from './use-set-search-params-and-local-storage';

export function useCalendarParams() {
  const [searchParams, setSearchParams] = useSearchParams();

  const setSearchParamsAndLocalStorage = useSetSearchParamsAndLocalStorage(searchParams);

  const teamMemberId = searchParams.get('clr-mbr-id');
  const workplaceId = searchParams.get('clr-wpl-id');
  const viewMode = searchParams.get('clr-view');
  const calendarDate = searchParams.get('clr-date');

  function getDateDisplay() {
    if (!calendarDate) return '';

    if (viewMode === 'week') {
      return getWeekRange(calendarDate);
    }

    return format(calendarDate, 'eee, d MMMM yyyy');
  }

  function navigateDate(updatedDate: string) {
    setSearchParamsAndLocalStorage('clr-date', updatedDate);

    setSearchParams(searchParams);
  }

  return {
    teamMemberId,
    workplaceId,
    viewMode,
    calendarDate,
    setSearchParams,
    navigateDate,
    getDateDisplay,
  };
}
