import React from 'react';
import { Icon } from '../../../../icon/icon';
import { SectionHeader } from '../section-header/section-header';
import { AccountHeader } from './components/account-header/account-header';
import { HeaderContainer, LeftSection } from './header.styled';

export function Header(): JSX.Element {
  return (
    <HeaderContainer id="header-container">
      <LeftSection>
        <Icon size="80px" type="PataLogo" />
        <SectionHeader isMainHeader />
      </LeftSection>
      <AccountHeader />
    </HeaderContainer>
  );
}
