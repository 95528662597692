import { type UseQueryResult, useQuery, useMutation, type UseQueryOptions, type QueryKey } from 'react-query';
import {
  type ScheduleSearch,
  type Member,
  type MemberSearch,
  type ScheduledShift,
  type TimeOff,
} from '../types/member.types';
import {
  deleteMember,
  deleteTimeOff,
  getMember,
  getMembers,
  getMembersSchedule,
  getTimeOff,
  patchMember,
  patchTimeOff,
  postMember,
  postTimeOff,
  putShift,
} from '../api/member.api';
import { useGetUser } from './user.queries';
import { queryClient } from './query-client';

export function useGetMembers(
  queryKey: QueryKey,
  queryString: string,
  options?: UseQueryOptions<MemberSearch, Error>,
): UseQueryResult<MemberSearch> {
  const { data } = useGetUser();

  const accountId = data?.accounts?.[0];

  return useQuery<MemberSearch, Error>(queryKey, () => getMembers(queryString), {
    enabled: Boolean(accountId),
    ...options,
  });
}


export function useGetMember(
  memberId: string | null,
  options?: UseQueryOptions<Member, Error>,
): UseQueryResult<Member> {
  return useQuery<Member, Error>(['member', memberId], () => getMember(memberId as string), {
    staleTime: 100000,
    enabled: Boolean(memberId),
    ...options,
  });
}

export const usePatchMember = () => {
  return useMutation('patchMember', {
    mutationFn: (data: { id: string; memberDetails: Partial<Member> }) => patchMember(data.id, data.memberDetails),
  });
};

export const useDeleteMember = () => {
  return useMutation('deleteMember', {
    mutationFn: (id: string) => deleteMember(id),
  });
};

export const usePostMember = () => {
  return useMutation('postMember', {
    mutationFn: (memberDetails: Partial<Member>) => postMember(memberDetails),
  });
};

export const prefetchMembers = async () => {
  await queryClient.prefetchQuery({
    queryKey: 'members',
    queryFn: () => getMembers(),
  });
};

export function useGetMembersSchedule(
  queryKey: QueryKey,
  queryString: URLSearchParams | undefined,
  options?: UseQueryOptions<ScheduleSearch, Error>,
): UseQueryResult<ScheduleSearch> {
  const { data } = useGetUser();

  const accountId = data?.accounts?.[0];

  return useQuery<ScheduleSearch, Error>(queryKey, () => getMembersSchedule(queryString), {
    enabled: Boolean(accountId),
    ...options,
  });
}

export const usePutScheduledShift = () => {
  return useMutation('putScheduledShift', {
    mutationFn: (data: { id: string; shiftDetails: Partial<ScheduledShift> }) => putShift(data.id, data.shiftDetails),
  });
};

export function useGetTimeOff(
  memberId: string | null,
  timeOffId: string,
  options?: UseQueryOptions<TimeOff, Error>,
): UseQueryResult<TimeOff> {
  return useQuery<TimeOff, Error>(['timeOff', memberId, timeOffId], () => getTimeOff(memberId as string, timeOffId), {
    staleTime: 100000,
    enabled: Boolean(memberId) && Boolean(timeOffId),
    ...options,
  });
}

export const usePatchTimeOff = () => {
  return useMutation('patchTimeOff', {
    mutationFn: (data: { memberId: string; timeOffId: string; timeOffDetails: Partial<TimeOff> }) =>
      patchTimeOff(data.memberId, data.timeOffId, data.timeOffDetails),
  });
};

export const useDeleteTimeOff = () => {
  return useMutation('deleteMember', {
    mutationFn: (data: { memberId: string; timeOffId: string }) => deleteTimeOff(data.memberId, data.timeOffId),
  });
};

export const usePostTimeOff = () => {
  return useMutation('postMember', {
    mutationFn: (data: { memberId: string; timeOffDetails: Partial<TimeOff> }) =>
      postTimeOff(data.memberId, data.timeOffDetails),
  });
};
