import { signIn } from 'aws-amplify/auth';
import { useCallback } from 'react';

export function useLogin() {
  const handleLogin = useCallback(async (email: string, password: string) => {
    await signIn({
      username: email,
      password,
    });
  }, []);

  return handleLogin;
}
