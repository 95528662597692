import { createContext, useContext } from 'react';

interface AppScrollContext {
  scrollFromTop: number;
}

export const AppScrollContext = createContext<AppScrollContext>({
  scrollFromTop: 0,
});

export function useAppScrollContext(): AppScrollContext {
  const context = useContext(AppScrollContext);

  return context;
}
