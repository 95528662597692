import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuthContext } from '../../contexts/auth.context';
import { useGetUser } from '../../queries/user.queries';

export function AuthedOnboard() {
  const { isAuthenticated } = useAuthContext();

  const { data } = useGetUser({ enabled: isAuthenticated ?? false });

  if (!data?.accounts?.length && isAuthenticated) return <Navigate to="/auth" />;

  return isAuthenticated ? <Navigate to="/calendar" /> : null;
}
