export function getDateFromString(date: string) {
  const formattedBaseDate = new Date(
    Date.UTC(Number(date.split('-')[0]), Number(date.split('-')[1]) - 1, Number(date.split('-')[2])),
  ).toISOString();

  const updatedDate = new Date(formattedBaseDate);

  const year = updatedDate.getUTCFullYear();
  const month = updatedDate.getUTCMonth() + 1;
  const day = updatedDate.getUTCDate();
  const hours = updatedDate.getUTCHours();
  const minutes = updatedDate.getUTCMinutes();
  const seconds = updatedDate.getUTCSeconds();

  return `${year}-${String(month).padStart(2, '0')}-${String(day).padStart(2, '0')} ${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
}
