import React from 'react';
import { useTranslation } from 'react-i18next';
import { useMenuPath } from '../navigation/hooks/use-menu-path';
import { Icon } from '../../../../icon/icon';
import { useAppScrollContext } from '../../contexts/app-scroll.context';
import { BackLink, SectionHeaderContainer, TitleContainer } from './section-header.styled';

export interface SectionHeaderProps {
  children?: React.ReactNode;
  isMainHeader?: boolean;
  title?: string;
  description?: string;
}

export function SectionHeader({ children, isMainHeader, title, description }: SectionHeaderProps) {
  const { currentRoutesConfig } = useMenuPath();

  const { t } = useTranslation(['translation', 'routes']);

  const { scrollFromTop } = useAppScrollContext();

  const routeTitle = title || currentRoutesConfig?.label;
  const routeDescription = description || currentRoutesConfig?.description;

  const showTitle = !isMainHeader || scrollFromTop > 70;

  const backLinkPath = currentRoutesConfig?.mobileBackLink;
  const showBackButton = isMainHeader && backLinkPath;

  return (
    <SectionHeaderContainer $isMainHeader={Boolean(isMainHeader)} $showTitle={showTitle}>
      {Boolean(showBackButton) && (
        <BackLink to={backLinkPath}>
          <Icon size="25px" type="Arrow" />
        </BackLink>
      )}

      <TitleContainer>
        <h1>{Boolean(routeTitle) && t(routeTitle, { ns: 'routes' })}</h1>
        <p>{Boolean(routeDescription) && t(routeDescription, { ns: 'routes' })}</p>
      </TitleContainer>
      {children}
    </SectionHeaderContainer>
  );
}
