import { Button, FormField, Input } from '@pata-app/ui-form';
import { useSearchParams } from 'react-router-dom';
import React, { useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { Controller, useForm } from 'react-hook-form';
import { usePostForgottenPassword } from '../../queries/user.queries';
import { Icon } from '../../components/icon/icon';
import { emailPattern } from '../../lib/helpers/validations';
import { AuthFormContainer } from '../../components/auth-form-container/auth-form-container';
import { ConfirmationContainer } from './forgotten-password.styled';

export function ForgottenPassword() {
  const [showConfirmation, setShowConfirmation] = useState(false);

  const [searchParams] = useSearchParams();

  const {
    register,
    trigger,
    formState: { isValid, errors },
    getValues,
    control,
  } = useForm({
    defaultValues: {
      email: searchParams.get('email') ?? '',
      recaptcha: null,
    },
    mode: 'onBlur',
  });

  const mutation = usePostForgottenPassword();

  const handleSubmit = async () => {
    try {
      await trigger();
      if (isValid) {
        const formValues = getValues();
        mutation.mutate(
          { email: formValues.email, recaptcha: formValues.recaptcha, origin: 'partners' },
          {
            onSuccess: () => {
              setShowConfirmation(true);
            },
          },
        );
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <AuthFormContainer>
      <Icon size="150px" type="PataLogo" />
      {!showConfirmation ? (
        <>
          <h1>
            <span>Request </span>
            <span>Password reset </span>
          </h1>
          <h2>Provide the email address for your Pata account to reset your password</h2>

          <FormField error={errors.email?.message} label="Email">
            <Input
              {...register('email', {
                required: 'Email is required',
                maxLength: { value: 254, message: 'Max character length is 254' },
                pattern: {
                  message: 'Not a valid email',
                  value: emailPattern,
                },
                setValueAs: (value: string) => value.trim(),
              })}
              hasError={Boolean(errors.email)}
              placeholder="Enter email address"
              type="email"
            />
          </FormField>
          <FormField error={errors.recaptcha?.message}>
            <Controller
              control={control}
              name="recaptcha"
              render={({ field: renderField }) => {
                return <ReCAPTCHA {...renderField} sitekey={process.env.RECAPTCHA_SITE_KEY} />;
              }}
              rules={{
                required: 'Recapcha is required',
              }}
            />
          </FormField>
          <Button isLoading={mutation.isLoading} onClick={() => void handleSubmit()}>
            Request Reset
          </Button>
        </>
      ) : (
        <ConfirmationContainer>
          <h1>
            <span>Reset password email sent to </span>
            <span>{getValues('email')}</span>
          </h1>
          <p>We have sent you an email containing instructions on how to reset your password</p>
        </ConfirmationContainer>
      )}
    </AuthFormContainer>
  );
}
