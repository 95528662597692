import React from 'react';
import { useTranslation } from 'react-i18next';
import { useMenuPath } from '../../../hooks/use-menu-path';
import { MenuContainer, MenuItemsContainer, MenuTitle } from './sub-menu.styled';
import { MenuItem } from './menu/components/menu-item';

export function SubMenu(): JSX.Element | null {
  const { currentSubMenuConfig, currentPrimaryMenuConfig, handleSelect, currentSubMenuRoute } = useMenuPath();

  const { t } = useTranslation();

  // console.log({ currentSubMenuConfig, currentPrimaryMenuConfig, handleSelect, currentSubMenuRoute });

  const translatedSubMenuConfig = currentSubMenuConfig?.map((menuItem) => {
    return {
      ...menuItem,
      label: t(menuItem.label, { ns: 'routes' }),
      description: t(menuItem.description, { ns: 'routes' }),
    };
  });

  const handleClick = (id: string): void => {
    handleSelect(id);
  };

  if (currentSubMenuConfig) {
    return (
      <MenuContainer>
        {Boolean(currentPrimaryMenuConfig?.label ? t(currentPrimaryMenuConfig.label, { ns: 'routes' }) : '') && (
          <MenuTitle>
            {currentPrimaryMenuConfig?.label ? t(currentPrimaryMenuConfig.label, { ns: 'routes' }) : ''}
          </MenuTitle>
        )}
        <MenuItemsContainer>
          {translatedSubMenuConfig?.map((item) => {
            return (
              <MenuItem
                {...item}
                isSelected={currentSubMenuRoute === item.path}
                key={item.path}
                onSelect={handleClick}
              />
            );
          })}
        </MenuItemsContainer>
      </MenuContainer>
    );
  }

  return null;
}
