import styled from 'styled-components';
import { type PlaceholderTypes } from './profile-icon';

interface ProfileIconContainer {
  $size: number;
  $colour?: string;
  $placeholderType: PlaceholderTypes;
}

export const ProfileIconPlaceholder = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  font-weight: ${({ theme }) => theme.core.fontWeight.semiBold};
  font-family: ${({ theme }) => theme.core.fontFamily.jost};
  text-transform: uppercase;
`;

export const ProfileIconContainer = styled.div<ProfileIconContainer>`
  height: ${({ $size }) => $size}px;
  width: ${({ $size }) => $size}px;
  min-height: ${({ $size }) => $size}px;
  min-width: ${({ $size }) => $size}px;

  ${ProfileIconPlaceholder}, img {
    width: 100%;
    height: 100%;

    border-radius: 50%;

    overflow: hidden;
  }

  ${ProfileIconPlaceholder} {
    font-size: ${({ $size }) => $size / 2.8}px;

    background: ${({ $colour, theme, $placeholderType }) =>
      $placeholderType === 'solid' ? $colour : theme.core.colors.greyscale.white};

    border: ${({ $size, $placeholderType }) => ($placeholderType === 'solid' ? 0 : $size / 18)}px solid
      ${({ $colour, theme, $placeholderType }) =>
        $placeholderType === 'outline' ? $colour : theme.core.colors.greyscale.white};

    color: ${({ theme, $placeholderType, $colour }) =>
      $placeholderType === 'solid' ? theme.core.colors.greyscale.white : $colour};
  }

  img {
    object-fit: cover;
  }
`;
