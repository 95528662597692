import { type UseQueryResult, useQuery, type UseQueryOptions } from 'react-query';
import {
  getCategoryConfig,
  getConfig,
  getNotificationsConfig,
  getPetsConfig,
  getServiceTypesConfig,
  getOnboardingConfigProviders,
  getGeneralConfig,
  getTerminalConfig,
} from '../api/config.api';
import {
  type PetsConfig,
  type CategoryConfig,
  type ServiceTypesConfig,
  type NotificationConfig,
  type OnboardingConfig,
  GeneralConfig,
  TerminalConfig,
} from '../types/config.types';

export function useGetConfig(options?: UseQueryOptions<any, Error>): UseQueryResult {
  return useQuery<any, Error>('config', getConfig, options);
}

export function useGetCategoryConfig(options?: UseQueryOptions<CategoryConfig, Error>): UseQueryResult {
  return useQuery<CategoryConfig, Error>('categoryConfig', getCategoryConfig, options);
}

export function useGetPetsConfig(options?: UseQueryOptions<PetsConfig, Error>): UseQueryResult {
  return useQuery<PetsConfig, Error>('petsConfig', getPetsConfig, options);
}

export function useGetServiceTypesConfig(options?: UseQueryOptions<ServiceTypesConfig, Error>): UseQueryResult {
  return useQuery<ServiceTypesConfig, Error>('serviceTypesConfig', getServiceTypesConfig, options);
}

export function useGetGeneralConfig(options?: UseQueryOptions<GeneralConfig, Error>): UseQueryResult {
  return useQuery<GeneralConfig, Error>('generalConfig', getGeneralConfig, options);
}

export function useGetTerminalConfig(options?: UseQueryOptions<TerminalConfig, Error>): UseQueryResult {
  return useQuery<TerminalConfig, Error>('terminalConfig', getTerminalConfig, options);
}

export function useGetOnboardingProvidersConfig(options?: UseQueryOptions<OnboardingConfig, Error>) {
  return useQuery('onboardingconfig', getOnboardingConfigProviders, options);
}

export function useGetNotificationsConfig(
  options?: UseQueryOptions<NotificationConfig, Error>,
): UseQueryResult<NotificationConfig> {
  return useQuery<NotificationConfig, Error>('notificationConfig', getNotificationsConfig, {
    ...options,
    // cacheTime: 7200000,
  });
}
