import React from 'react';
import { Loading } from '@pata-app/ui-form';
import { LoaderContainer } from './loader.styled';

interface LoaderProps {
  size?: number;
  type?: 'standard' | 'pata';
  addPadding?: boolean;
}

export function Loader({ size = 100, type = 'pata', addPadding = true }: LoaderProps) {
  return (
    <LoaderContainer $addPadding={addPadding}>
      <Loading size={size} type={type} />
    </LoaderContainer>
  );
}
