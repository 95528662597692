import { type Method } from 'axios';
import { methods, request } from '../lib';
import { type Workplace } from '../types/workplace.types';
import { endpoints } from './endpoints';

export async function getWorkplaces(): Promise<Workplace[]> {
  const data = await request({
    endpoint: endpoints.workplaces,
    method: methods.GET as Method,
  });

  return data.data.data;
}

export async function getWorkplace(businessId: string): Promise<Workplace> {
  const data = await request<Workplace>({
    endpoint: endpoints.workplace(businessId),
    method: methods.GET as Method,
  });

  return data?.data?.data;
}

export async function postWorkplace(body: Partial<Workplace>): Promise<void> {
  await request({
    endpoint: endpoints.workplaces,
    method: methods.POST as Method,
    body,
  });
}

export async function patchWorkplace(businessId: string, body: Partial<Workplace>): Promise<void> {
  await request({
    endpoint: endpoints.workplace(businessId),
    method: methods.PATCH as Method,
    body,
  });
}

export async function deleteWorkplace(businessId: string): Promise<void> {
  await request({
    endpoint: endpoints.workplace(businessId),
    method: methods.DELETE as Method,
  });
}
