import { type UseQueryResult, useQuery, useMutation, type UseQueryOptions } from 'react-query';
import { type Account } from '../types/account.types';
import { getAccount, patchAccount } from '../api/account.api';
import { useGetUser } from './user.queries';

export function useGetAccount(options?: UseQueryOptions<Account, Error>): UseQueryResult<Account> {
  const { data } = useGetUser();

  const accountId = data?.accounts?.[0];

  return useQuery<Account, Error>(['account'], () => getAccount(accountId as string), {
    staleTime: 100000,
    enabled: Boolean(accountId),
    ...options,
  });
}

export const usePatchAccount = () => {
  const { data } = useGetUser();

  const accountId = data?.accounts?.[0];

  return useMutation('patchAccount', {
    mutationFn: (accountDetails: Partial<Account>) => patchAccount(accountId, accountDetails),
  });
};
