import { type UseQueryResult, useQuery, useMutation, type UseQueryOptions } from 'react-query';
import { deleteWorkplace, getWorkplace, getWorkplaces, patchWorkplace, postWorkplace } from '../api/workplace.api';
import { type Workplace } from '../types/workplace.types';
import { useGetUser } from './user.queries';
import { queryClient } from './query-client';

export function useGetWorkplaces(options?: UseQueryOptions<Workplace[], Error>): UseQueryResult<Workplace[]> {
  const { data } = useGetUser();
  
  const accountId = data?.accounts?.[0];

  return useQuery<Workplace[], Error>('workplaces', () => getWorkplaces(), {
    staleTime: 100000,
    enabled: Boolean(accountId),
    ...options,
  });
}

export function useGetWorkplace(
  businessId: string | null,
  options?: UseQueryOptions<Workplace, Error>,
): UseQueryResult<Workplace> {
  return useQuery<Workplace, Error>(['workplace', businessId], () => getWorkplace(businessId as string), {
    staleTime: 100000,
    enabled: Boolean(businessId),
    ...options,
  });
}

export const usePatchWorkplace = () => {
  return useMutation('patchWorkplace', {
    mutationFn: (data: { id: string; businessDetails: Partial<Workplace> }) =>
      patchWorkplace(data.id, data.businessDetails),
  });
};

export const useDeleteWorkplace = () => {
  return useMutation('deleteWorkplace', {
    mutationFn: (id: string) => deleteWorkplace(id),
  });
};

export const usePostWorkplace = () => {
  return useMutation('postWorkplace', {
    mutationFn: (businessDetails: Partial<Workplace>) => postWorkplace(businessDetails),
  });
};

export const prefetchWorkplaces = async () => {
  await queryClient.prefetchQuery({
    queryKey: 'workplaces',
    queryFn: getWorkplaces,
  });
};
