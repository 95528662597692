import React from 'react';
import { Image } from '../image/image';
import { Icon } from '../icon/icon';
import { Checkbox } from '../checkbox/checkbox';
import { TileContainer, ImageContainer } from './resource-tile.styled';

interface TileComponentProps {
  title: string;
  description?: string;
  icon?: string;
  placeholderIcon: string;
  isSelected: boolean;
  isCompact: boolean;
}

export function ResourceTile({
  title,
  icon,
  description,
  placeholderIcon,
  isSelected,
  isCompact = false,
  hideCheckbox = false,
}: TileComponentProps) {
  return (
    <TileContainer $isCompact={isCompact}>
      <ImageContainer $hasImage={Boolean(icon)}>
        {icon ? <Image src={`${process.env.CDN_BASE_URL_USER_CONTENT}/${icon}`} /> : <Icon type={placeholderIcon} />}
      </ImageContainer>
      <span>{title}</span>
      {!hideCheckbox && <Checkbox checked={isSelected} size={28} />}
      <span>{description}</span>
    </TileContainer>
  );
}
