import { type DateLocalizer, type DateRange } from 'react-big-calendar';

export function generateCalendarTimeFormats() {
  return {
    timeGutterFormat: (time: Date, culture: string, localizer: DateLocalizer) =>
      localizer.format(time, 'h:mm a', culture).toLowerCase(),
    eventTimeRangeFormat: (range: DateRange, culture: string, localizer: DateLocalizer) => {
      return `${localizer.format(range.start, 'h:mm', culture)} - ${localizer.format(range.end, 'h:mm', culture)}`;
    },
  };
}
