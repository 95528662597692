import React from 'react';
import { Button, FormField, Input, TelephoneInput } from '@pata-app/ui-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { Controller, useForm } from 'react-hook-form';
import { useSignUp } from '../../hooks/use-sign-up';
import { phoneNumberPattern } from '../../lib/helpers/validations';

interface RegisterForm {
  firstName: string;
  lastName: string;
  password: string;
  confirmPassword: string;
  mobile: string;
}

interface RegisterStepParams {
  email: string;
}

export function RegisterStep({ email }: RegisterStepParams) {
  const { t } = useTranslation('register');
  const { register: signUp, isSignupLoading } = useSignUp({ autoSignIn: true });

  const { register, getValues, formState, control } = useForm<RegisterForm>({
    mode: 'onBlur',
    defaultValues: {
      firstName: '',
      lastName: '',
      password: '',
      confirmPassword: '',
      mobile: '',
    },
  });

  const handleSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault();

    try {
      const { password: formPassword, firstName, lastName, mobile } = getValues();

      await signUp({
        email,
        password: formPassword,
        firstName,
        lastName,
        mobile,
      });
    } catch (e) {
      const error = e as Error;

      toast.error(error.message);
    }
  };

  return (
    <form
      autoComplete="off"
      onSubmit={(e) => {
        void handleSubmit(e);
      }}
    >
      <FormField error={formState.errors.firstName?.message} label="First name">
        <Input
          {...register('firstName', {
            maxLength: { value: 50, message: 'Max character length is 50' },
            required: 'First name is required',
            setValueAs: (value: string) => value.trim(),
          })}
          autoComplete="given-name"
          hasError={formState.errors.firstName}
        />
      </FormField>

      <FormField error={formState.errors.lastName?.message} label="Last name">
        <Input
          {...register('lastName', {
            maxLength: { value: 50, message: 'Max character length is 50' },
            required: 'Last name is required',
            setValueAs: (value: string) => value.trim(),
          })}
          autoComplete="given-name"
          hasError={formState.errors.lastName}
        />
      </FormField>

      <FormField error={formState.errors.mobile?.message} label="Mobile number">
        <Controller
          control={control}
          name="mobile"
          render={({ field }) => {
            return <TelephoneInput {...field} countryCode="+44" hasError={Boolean(formState.errors.mobile)} />;
          }}
          rules={{
            required: 'Phone Number is required',
            pattern: {
              value: phoneNumberPattern,
              message: 'Not a valid mobile phone number',
            },
          }}
        />
      </FormField>

      <FormField error={formState.errors.password?.message} label="Password">
        <Input
          type="password"
          {...register('password', {
            required: 'Password is required',
            pattern: {
              value:
                /^(?!\s+)(?!.*\s+$)(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[$^*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ])[A-Za-z0-9$^*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ]{8,256}$/,
              message:
                'Not a valid password. Must contain  at least 8 characters including a uppercase, lowercase, number and symbol character.',
            },
          })}
          autoComplete="new-password"
        />
      </FormField>

      <FormField error={formState.errors.confirmPassword?.message} label="Confirm password">
        <Input
          type="password"
          {...register('confirmPassword', {
            required: 'Confirm Password is required',
            validate: (value) => (value === getValues().password ? true : 'Passwords do not match'),
          })}
        />
      </FormField>

      <Button disabled={!formState.isValid} isLoading={isSignupLoading} type="submit">
        Register
      </Button>
    </form>
  );
}
