import React from 'react';
import { useMenuPath } from '../../../hooks/use-menu-path';
import { MenuItem } from '../../../components/menu-item/menu-item';
import { SectionHeader } from '../../../../section-header/section-header';

export function MobileSubMenu(): JSX.Element | null {
  const { currentSubMenuConfig, currentPrimaryRoute, hasSelectedRoute } = useMenuPath();

  if (!hasSelectedRoute && currentSubMenuConfig) {
    return (
      <div>
        <SectionHeader />

        {currentSubMenuConfig.map((option) => (
          <MenuItem key={option.path} {...option} path={`${currentPrimaryRoute}/${option.path}`} />
        ))}
      </div>
    );
  }

  return null;
}
