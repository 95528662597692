import { Button, FormField, Input } from '@pata-app/ui-form';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { Icon } from '../../components/icon/icon';
import { AuthFormContainer } from '../../components/auth-form-container/auth-form-container';
import { useLogin } from '../../hooks/use-login';
import { useGetPasswordReset, usePostResetPassword } from '../../queries/user.queries';

export function ResetPassword() {
  const { t } = useTranslation('auth');

  const {
    register,
    trigger,
    formState: { isValid, errors },
    getValues,
  } = useForm({
    defaultValues: {
      password: '',
      confirmPassword: '',
    },
  });

  const handleLogin = useLogin();

  const [searchParams] = useSearchParams();

  const resetId = searchParams.get('rid');

  const navigate = useNavigate();

  const { data, isError } = useGetPasswordReset(resetId ?? '');

  const mutation = usePostResetPassword();

  useEffect(() => {
    if (isError || !resetId) {
      navigate('/login');
    }
  }, [isError, resetId, navigate]);

  const handleResetPassword = async () => {
    try {
      await trigger();
      if (isValid) {
        const formValues = getValues();
        mutation.mutate(
          { resetId: resetId ?? '', password: formValues.password },
          {
            onSuccess: () => {
              handleLogin(data.email, formValues.password);
            },
            onError: () => {},
          },
        );
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <AuthFormContainer>
      <Icon size="150px" type="PataLogo" />
      <h1>
        <span>Reset your</span>
        <span>Pata access</span>
      </h1>
      <h2>Enter your new password</h2>

      <FormField error={errors.password?.message} label="Password">
        <Input
          type="password"
          {...register('password', {
            required: t('form_error_messages_password_required'),
            pattern: {
              value:
                /^(?!\s+)(?!.*\s+$)(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[$^*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ])[A-Za-z0-9$^*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ]{8,256}$/,
              message: t('form_error_messages_password_invalid'),
            },
          })}
          autoComplete="new-password"
        />
      </FormField>

      <FormField error={errors.confirmPassword?.message} label="Confirm password">
        <Input
          type="password"
          {...register('confirmPassword', {
            required: t('form_error_messages_confirm_password_invalid'),
            validate: (value) =>
              value === getValues().password ? true : t('form_error_messages_confirm_password_invalid'),
          })}
        />
      </FormField>

      <Button isLoading={mutation.isLoading} onClick={() => void handleResetPassword()}>
        Reset Password
      </Button>
    </AuthFormContainer>
  );
}
