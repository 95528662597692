import styled from 'styled-components';

interface ImageContainerProps {
  $hasImage: boolean;
}

export const ImageContainer = styled.div<ImageContainerProps>`
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;

  height: ${({ $hasImage }) => ($hasImage ? 50 : 25)}px;
  width: ${({ $hasImage }) => ($hasImage ? 50 : 40)}px;

  border-radius: ${({ theme }) => theme.core.border.container.radius}px;
  border: ${({ $hasImage }) => ($hasImage ? 2 : 0)}px solid ${({ theme }) => theme.core.colors.greyscale.white};

  img {
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    object-fit: cover;

    border-radius: ${({ theme }) => theme.core.border.container.radius}px;
  }

  svg {
    fill: ${({ theme }) => theme.core.colors.primary.green.medium};
    color: ${({ theme }) => theme.core.colors.primary.green.medium};
  }
`;

export const TileContainer = styled.div`
  display: grid;
  align-items: center;
  grid-template-rows: ${({ $isCompact }) => ($isCompact ? 'auto auto' : 'min-content min-content auto')};
  grid-template-columns: ${({ $isCompact }) => ($isCompact ? 'min-content auto min-content' : 'auto min-content')};
  column-gap: ${({ theme }) => theme.core.sizing[2]}px;
  row-gap: ${({ theme }) => theme.core.sizing[1]}px;

  height: 100%;

  span:first-of-type {
    font-weight: ${({ theme }) => theme.core.fontWeight.medium};
    font-size: ${({ theme }) => theme.core.fontSizes.l}px;
    text-align: left;

    grid-row: ${({ $isCompact }) => ($isCompact ? '1 / span 1' : '2 / span 1')};
    grid-column: ${({ $isCompact }) => ($isCompact ? '2 / span 1' : '1 / span 2')};
  }

  span:last-of-type {
    font-size: ${({ theme }) => theme.core.fontSizes.m}px;
    color: ${({ theme }) => theme.core.colors.greyscale.dark};
    text-align: left;

    grid-row: ${({ $isCompact }) => ($isCompact ? '2 / span 1' : '3 / span 1')};
    grid-column: ${({ $isCompact }) => ($isCompact ? '1 / span 2' : '1 / span 2')};
  }

  svg {
    height: 100%;
    width: 100%;

    fill: ${({ theme }) => theme.core.colors.primary.green.medium};
  }

  ${ImageContainer} {
    height: ${({ $isCompact, $isImage }) => ($isCompact ? (25 ? $isImage : 50) : 40)}px;
    width: ${({ $isCompact, $isImage }) => ($isCompact ? (25 ? $isImage : 50) : 40)}px;
  }
`;
