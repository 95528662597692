import styled from 'styled-components';
import { SectionHeaderContainer } from '../section-header/section-header.styled';

export const HeaderContainer = styled.header`
  position: fixed;
  top: 0;
  left: 0;

  display: flex;
  align-items: center;
  flex: 0 0 70px;
  gap: ${({ theme }) => theme.core.sizing[2]}px;

  height: 70px;
  width: 100%;

  padding: 0 ${({ theme }) => theme.core.sizing[2]}px;

  background: ${({ theme }) => theme.core.colors.primary.green.medium};

  z-index: 11;

  ${({ theme }) => theme.breakpoints.up('sm')} {
    position: relative;
  }
`;

export const LeftSection = styled.div`
  margin-right: auto;

  > svg {
    display: none;
  }

  ${({ theme }) => theme.breakpoints.up('sm')} {
    svg {
      display: block;
      color: ${({ theme }) => theme.core.colors.greyscale.white};
    }

    ${SectionHeaderContainer} {
      display: none;
    }
  }
`;
