import styled from 'styled-components';

export const AppLayoutContainer = styled.div`
  height: 100%;
`;

export const DemoIframe = styled.iframe`
  width: 100%;
  height: calc(100% - 10px);

  overflow: hidden;

  border: none;
`;
