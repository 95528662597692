import React, { createContext, useContext } from 'react';
import { useAuth } from '../hooks/use-auth';

interface AuthContextProps {
  isAuthenticated: boolean | null;
  checkUserAuth: () => Promise<void>;
}

export const AuthContext = createContext<AuthContextProps>({
  isAuthenticated: false,
  checkUserAuth: () => new Promise(() => {}),
});

interface AuthProviderProps {
  children: React.ReactNode;
}

export function AuthProvider({ children }: AuthProviderProps) {
  const { isAuthenticated, checkUserAuth } = useAuth();

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        checkUserAuth,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export const useAuthContext = () => {
  return useContext(AuthContext);
};
