import React from 'react';
import { type ResourceHeaderProps } from 'react-big-calendar';
import { ProfileIcon } from '../../../../components/profile-icon/profile-icon';
import { ResourceContainerContainer } from './resource-container.styled';

interface ResourceProps {
  firstName: string;
  lastName: string;
  profilePicture: string;
  calendarColour: string;
}

export function ResourceContainer(event: ResourceHeaderProps<ResourceProps>) {
  return (
    <ResourceContainerContainer>
      <ProfileIcon
        colour={event.resource.calendarColour}
        firstName={event.resource.firstName}
        lastName={event.resource.lastName}
        profilePicture={event.resource.profilePicture}
      />
      {event.label}
    </ResourceContainerContainer>
  );
}
