import React, { useEffect, type PropsWithChildren } from 'react';
import { prefetchClients } from '../../../../queries/client.queries';
import { prefetchWorkplaces } from '../../../../queries/workplace.queries';
import { prefetchCatalogueServices } from '../../../../queries/catalogue.queries';
import { prefetchTerminals } from '../../../../queries/terminals.queries';

export function PrefetchWrapper({ children }: PropsWithChildren) {
  useEffect(() => {
    void prefetchClients();
    void prefetchWorkplaces();
    void prefetchCatalogueServices();
    void prefetchTerminals();
  }, []);

  return <>{children}</>;
}
