import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { DateTime } from 'luxon';
import { useGetMembersSchedule } from '../../../queries/member.queries';
import { useCalendarParams } from '../components/calendar/hooks/use-calendar-params';
import { type CalendarEvent } from '../../../types/appointments.types';
import { generateBackgroundEvents } from '../helpers/generate-closed-times';
import { useCalendarEventRequestParams } from './use-calendar-event-request-params';

export function useBackgroundEvents() {
  const { teamMemberId, workplaceId, calendarDate } = useCalendarParams();
  const getSearchQueryString = useCalendarEventRequestParams();

  const { t } = useTranslation('team');

  const {
    data: schedule,
    isFetching,
    isLoading,
  } = useGetMembersSchedule(['schedule', teamMemberId, workplaceId, calendarDate], getSearchQueryString(), {
    enabled: Boolean(workplaceId),
    refetchInterval: 60000,
  });

  const formattedBackgroundEvents = useMemo(
    () => (schedule?.data ? generateBackgroundEvents(schedule.data) : []),
    [schedule?.data],
  );

  const timeOffEvents = useMemo(() => {
    return schedule?.data.reduce((events: CalendarEvent[], memberSchedule) => {
      return [
        ...events,
        ...memberSchedule.schedules.reduce((memberTimeOffEvents, scheduleDay) => {
          if (scheduleDay.timeOff?.length) {
            return [
              ...memberTimeOffEvents,
              ...scheduleDay.timeOff.map((timeOff) => ({
                id: timeOff.id,
                title: `Time off`,
                description: t(timeOff.timeOffType),
                resourceId: memberSchedule.member.id,
                start: DateTime.fromSeconds(DateTime.fromISO(scheduleDay.scheduleDay).toSeconds() + timeOff.startTime)
                  .setZone('local')
                  .toJSDate(),

                end: DateTime.fromSeconds(DateTime.fromISO(scheduleDay.scheduleDay).toSeconds() + timeOff.endTime)
                  .setZone('local')
                  .toJSDate(),
                backgroundColour: '#cccccc',
                eventType: 'time-off',
                isDisabled: true,
              })),
            ];
          }
          return memberTimeOffEvents;
        }, []),
      ];
    }, []);
  }, [schedule?.data, t]);

  return { backgroundEvents: formattedBackgroundEvents, timeOffEvents, isLoading, isFetching };
}
