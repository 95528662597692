import styled from 'styled-components';

interface MenuItemContainerProps {
  $isSelected: boolean;
}

export const MenuItemContainer = styled.button<MenuItemContainerProps>`
  width: 100%;
  height: 50px;
  min-width: 120px;

  padding: ${({ theme }) => theme.core.sizing[1]}px;

  border-radius: ${({ theme }) => theme.core.border.container.radius}px;
  border: none;

  background: ${({ theme, $isSelected }) =>
    $isSelected ? theme.core.colors.greyscale.black : theme.core.colors.greyscale.light};

  color: ${({ theme, $isSelected }) =>
    $isSelected ? theme.core.colors.greyscale.white : theme.core.colors.greyscale.black};

  font-size: ${({ theme }) => theme.core.fontSizes.m}px;
  font-family: 'Jost';

  text-wrap: nowrap;

  transition: all 0.1s ease-in-out;

  cursor: pointer;

  outline: none;

  &:focus {
    box-shadow: var(--focus-ring, 0 0 0 2px white, 0 0 0 4px ${({ theme }) => theme.core.colors.primary.green.medium});
  }

  @media ${({ theme }) => theme.core.breakpoints.s} {
    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: 0 ${({ theme }) => theme.core.sizing[1]}px;

    border-radius: ${({ theme }) => theme.core.border.container.radius}px;

    background: ${({ theme, $isSelected }) =>
      $isSelected ? theme.core.colors.primary.green.medium : theme.core.colors.greyscale.white};
    color: ${({ theme, $isSelected }) =>
      $isSelected ? theme.core.colors.greyscale.white : theme.core.colors.greyscale.black};
    font-weight: ${({ theme, $isSelected }) =>
      $isSelected ? theme.core.fontWeight.medium : theme.core.fontWeight.regular};

    &:hover {
      background: ${({ theme }) => `${theme.core.colors.primary.green.medium}33`};
      color: ${({ theme }) => theme.core.colors.greyscale.black};
    }
  }
`;

export const MenuItemCount = styled.span`
  display: none;

  @media ${({ theme }) => theme.core.breakpoints.s} {
    display: flex;
    align-items: center;
    justify-content: center;

    height: 26px;
    width: 26px;

    border-radius: 13px;
    border: 2px solid ${({ theme }) => theme.core.colors.primary.green.medium};

    color: ${({ theme }) => theme.core.colors.primary.green.medium};

    background: ${({ theme }) => theme.core.colors.greyscale.white};

    font-size: ${({ theme }) => theme.core.fontSizes.s}px;
    font-weight: ${({ theme }) => theme.core.fontWeight.semiBold};
  }
`;
