export const BLOCK_TIME_OPTIONS = [
  {
    value: 'lunch',
    label: 'Lunch',
  },
  {
    value: 'training',
    label: 'Training',
  },
  {
    value: 'other',
    label: 'Other',
  },
];
