import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const MenuItemContainer = styled(Link)`
  display: flex;
  align-items: center;

  padding: 24px 0;

  text-decoration: none;

  color: ${({ theme }) => theme.core.colors.greyscale.black};

  &:not(:last-of-type) {
    border-bottom: 1px solid ${({ theme }) => theme.core.colors.greyscale.medium};
  }

  p {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    margin: 0;

    margin-left: ${({ theme }) => theme.core.sizing[2]}px;
  }

  svg {
    flex-shrink: 0;

    fill: ${({ theme }) => theme.core.colors.greyscale.black};
  }
`;

export const ItemTitle = styled.span`
  font-weight: ${({ theme }) => theme.core.fontWeight.medium};
`;
