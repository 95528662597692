import { Link } from 'react-router-dom';
import styled from 'styled-components';

interface QuickLinkProps {
  $isSelected?: boolean;
}

export const MobileNavigationContainer = styled.nav`
  position: fixed;
  bottom: 0;

  width: 100%;

  height: 60px;

  flex: 0 0 60px;

  display: flex;
  align-items: center;
  justify-content: space-around;

  border-top: 1px solid ${({ theme }) => theme.core.colors.greyscale.medium};
  background: ${({ theme }) => theme.core.colors.greyscale.white};

  z-index: 10;
`;

export const QuickLink = styled(Link)<QuickLinkProps>`
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    fill: ${({ theme, $isSelected }) =>
      $isSelected ? theme.core.colors.primary.green.medium : theme.core.colors.greyscale.black};

    transition: all 0.2s ease-in-out;
  }
`;
