import React, { lazy, Suspense, type RefObject } from 'react';
import { useScrollBarPosition } from '@pata-app/hooks';
import { Modal } from '@pata-app/ui-modal';
import { Navigate, Outlet, useSearchParams } from 'react-router-dom';
import { useTheme } from 'styled-components';
import { useMediaQuery } from 'styled-breakpoints/use-media-query';
import { useGetPetsConfig } from '../../../queries/config.queries';
import { useAuthContext } from '../../../contexts/auth.context';
import { StripeConnectProvider } from '../../stripe-connect-provider';
import { useGetAccount } from '../../../queries/account.queries';
import { useGetUser } from '../../../queries/user.queries';
import { DesktopWrapper } from './desktop-layout/desktop-layout';
import { AppLayoutContainer, DemoIframe } from './app-layout.styled';
import { MobileLayout } from './mobile-layout/mobile-layout';
import { TabletLayout } from './tablet-layout/tablet-layout';
import { AppScrollContext } from './contexts/app-scroll.context';
import { PrefetchWrapper } from './components/prefetch-wrapper';

const Drawer = lazy(() => import(/* webpackChunkName: "drawer" */ '../../drawers/drawer'));

export function AppLayout() {
  const { breakpoints } = useTheme();

  const [searchParams, setSearchParams] = useSearchParams();

  const showDemoModalParam = searchParams.get('book-demo');

  const showDemoModal = showDemoModalParam === 'true';

  const handleCloseDemoModal = () => {
    searchParams.delete('book-demo');

    setSearchParams(searchParams);
  };

  const { isAuthenticated } = useAuthContext();

  const { data, isLoading } = useGetUser({ enabled: Boolean(isAuthenticated) });

  useGetAccount({ enabled: Boolean(isAuthenticated) });

  const [ref, top] = useScrollBarPosition();

  useGetPetsConfig({ enabled: Boolean(isAuthenticated) });

  const isSm = useMediaQuery(breakpoints.down('sm'));
  const isMd = useMediaQuery(breakpoints.up('md'));
  const isLg = useMediaQuery(breakpoints.up('lg'));

  if (isLoading) return null;

  if (!data?.phoneNumber && isAuthenticated) {
    return <Navigate to="/post-auth/details" />;
  }

  if (!data?.accounts?.length && isAuthenticated) {
    return <Navigate to="/auth" />;
  }

  if (isAuthenticated === true) {
    return (
      <PrefetchWrapper>
        <AppLayoutContainer ref={ref as RefObject<HTMLDivElement>}>
          <StripeConnectProvider>
            {Boolean(isLg) && (
              <DesktopWrapper>
                <Suspense fallback={<></>}>
                  <Outlet />
                </Suspense>
              </DesktopWrapper>
            )}
            {Boolean(isMd) && !isLg && (
              <TabletLayout>
                <Suspense fallback={<></>}>
                  <Outlet />
                </Suspense>
              </TabletLayout>
            )}

            {Boolean(isSm) && (
              <AppScrollContext.Provider value={{ scrollFromTop: top }}>
                <MobileLayout>
                  <Suspense fallback={<></>}>
                    <Outlet />
                  </Suspense>
                </MobileLayout>
              </AppScrollContext.Provider>
            )}
            <Suspense fallback={<></>}>
              <Drawer />
            </Suspense>
          </StripeConnectProvider>
          {Boolean(showDemoModal) && (
            <Modal
              handleToggleModal={handleCloseDemoModal}
              hideFooter
              isOpen={showDemoModal}
              modalTitle={"Let's get you started!"}
              sizing={{
                width: '900px',
                maxWidth: '100vw',
                minWidth: '600px',
                height: `calc(100vh - 50px)`,
                maxHeight: `calc(100vh - 50px)`,
                minHeight: `calc(100vh - 50px)`,
              }}
            >
              <DemoIframe
                allowfullscreen
                frameborder="0"
                src="https://pata2.pipedrive.com/scheduler/DPxvorUq/pata-partners-onboarding"
                title="Pata Demo booking"
              />
            </Modal>
          )}
        </AppLayoutContainer>
      </PrefetchWrapper>
    );
  }

  if (isAuthenticated === false) {
    return <Navigate to="/login" />;
  }

  return null;
}
