import { selectStyles } from '@pata-app/ui-form';
import React from 'react';
import { useSearchParams } from 'react-router-dom';
import Select from 'react-select';
import { viewMenuOptions } from '../../../calendar.config';
import { useCalendarParams } from '../hooks/use-calendar-params';
import { useCalendarFilters } from '../hooks/use-calendar-filters';

export function ViewModeSelect() {
  const { viewMode } = useCalendarParams();

  const { memberOptions } = useCalendarFilters();

  const [searchParams, setSearchParams] = useSearchParams();

  const handleViewModeSelect = (updatedViewMode: string) => {
    searchParams.set('clr-view', updatedViewMode);

    if (viewMode === 'day' && updatedViewMode === 'week') {
      searchParams.set('clr-mbr-id', memberOptions[0].value ?? '');
    }

    setSearchParams(searchParams);
  };

  return (
    <Select
      menuPlacement="auto"
      onChange={(event) => {
        handleViewModeSelect(event?.value ?? 'week');
      }}
      options={viewMenuOptions}
      placeholder="Select view option"
      styles={selectStyles}
      value={viewMenuOptions?.find((option) => option.value === viewMode)}
    />
  );
}
