import React from 'react';
import { type DividerProps } from './divider.types';
import { DividerContainer } from './divider.styled';

export function Divider({ children }: DividerProps) {
  return (
    <DividerContainer>
      <hr />
      {Boolean(children) && <span>{children}</span>}
      <hr />
    </DividerContainer>
  );
}
