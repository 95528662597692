export const routesConfig = [
  {
    label: 'calendar',
    description: 'calendar_description',
    icon: 'Calendar',
    path: 'calendar',
    quickLink: true,
  },
  {
    label: 'profile',
    description: 'profile_description',
    icon: 'User',
    path: 'user',
    excludedFromNavigation: true,
  },

  {
    label: 'clients',
    description: 'clients_description',
    icon: 'Client',
    path: 'clients',
    quickLink: true,
  },
  {
    label: 'sales',
    description: 'sales_description',
    icon: 'Sales',
    path: 'sales',
    quickLink: true,
    subMenu: [
      {
        label: 'sales_list',
        description: 'sales_list_description',
        path: 'sales-list',
        group: 'Sales',
        mobileBackLink: '/sales',
      },
      {
        label: 'transactions',
        description: 'transactions_list_description',
        path: 'transactions-list',
        group: 'Sales',
        mobileBackLink: '/sales',
      },
      // {
      //   label: 'appointments_list',
      //   description: 'appointments_list_description',
      //   path: 'appointments-list',
      //   group: 'Sales',
      // },
      {
        label: 'membership_sales',
        description: 'membership_sales_description',
        path: 'memberships-list',
        group: 'Sales',
        mobileBackLink: '/sales',
      },
    ],
  },

  {
    label: 'catalogue',
    description: 'catalogue_description',
    icon: 'Catalogue',
    path: 'catalogue',
    mobileBackLink: '/menu',
    subMenu: [
      {
        label: 'services',
        description: 'services_description',
        path: 'services',
        group: 'Products',
        mobileBackLink: '/catalogue',
      },
      {
        label: 'memberships',
        description: 'memberships_description',
        path: 'memberships',
        group: 'Products',
        mobileBackLink: '/catalogue',
      },
      // {
      //   label: 'gift_cards',
      //   description: 'gift_cards_description',
      //   path: 'gift-cards',
      //   group: 'Products',
      // },
      // {
      //   label: 'add_ons',
      //   description: 'add_ons_description',
      //   path: 'add-ons',
      //   group: 'Products',
      // },
    ],
  },
  {
    label: 'team',
    description: 'team_description',
    icon: 'Team',
    path: 'team',
    mobileBackLink: '/menu',
    subMenu: [
      {
        label: 'team_members',
        description: 'team_members_description',
        path: 'members',
        group: 'Team',
        icon: 'Team',
        mobileBackLink: '/team',
      },
      {
        label: 'shifts',
        description: 'shifts_description',
        path: 'shifts',
        group: 'Team',
        icon: 'Calendar',
        mobileBackLink: '/team',
      },
    ],
  },
  {
    label: 'workplaces',
    description: 'workplaces_description',
    icon: 'Workplace',
    path: 'workplaces',
    mobileBackLink: '/menu',
  },
  {
    label: 'payments',
    description: 'payments_description',
    icon: 'Membership',
    path: 'payments',
    mobileBackLink: '/menu',
    subMenu: [
      {
        label: 'dashboard',
        description: 'payment_dashboard_description',
        path: 'dashboard',
        mobileBackLink: '/payments',
      },
      {
        label: 'terminal',
        description: 'terminal_description',
        path: 'terminal',
        mobileBackLink: '/payments',
      },
    ],
  },
  // {
  //   label: 'reports',
  //   description: 'reports_description',
  //   icon: 'Reports',
  //   path: 'reports',
  // },
  {
    label: 'settings',
    description: 'settings_description',
    icon: 'Settings',
    path: 'settings',
    mobileBackLink: '/menu',
    subMenu: [
      {
        label: 'account',
        description: 'account_description',
        path: 'account',
        group: 'Settings',
        icon: 'Settings',
        mobileBackLink: '/settings',
      },
      {
        label: 'closed_dates',
        description: 'closed_dates_description',
        path: 'closed-dates',
        icon: 'ClosedDate',
        mobileBackLink: '/settings',
      },
      // {
      //   label: 'team',
      //   description: 'team_description',
      //   path: 'team',
      //   group: 'Settings',
      //   icon: 'Team',
      // },
      {
        label: 'tax_rates',
        description: 'tax_description',
        path: 'tax-rates',
        group: 'Settings',
        icon: 'Tax',
        mobileBackLink: '/settings',
      },
      {
        label: 'notifications',
        description: 'notifications_description',
        path: 'notifications',
        icon: 'Notification',
        group: 'Settings',
        mobileBackLink: '/settings',
      },
    ],
  },
];

export const navigationConfig = routesConfig.filter((route) => !route.excludedFromNavigation);
