import { signUp, autoSignIn as autoSignInFn } from "aws-amplify/auth";
import { useState } from "react";

interface UseSignUpParams {
  autoSignIn: boolean;
}

interface UserRegisterParams {
  email: string;
  firstName: string;
  lastName: string;
  mobile: string;
  password: string;
}

export function useSignUp({ autoSignIn = true }: UseSignUpParams) {
  const [isSignupLoading, setIsSignupLoading] = useState(false);

  const register = async ({
    email,
    firstName,
    lastName,
    mobile,
    password,
  }: UserRegisterParams) => {
    setIsSignupLoading(true);
    try {
      const result = await signUp({
        username: email,
        password,
        options: {
          autoSignIn,
          userAttributes: {
            given_name: firstName,
            family_name: lastName,
            phone_number: mobile,
          },
        },
      });

      if (autoSignIn) {
        await autoSignInFn();
      }

      return result;
    } finally {
      setIsSignupLoading(false);
    }
  };

  return {
    register,
    isSignupLoading,
  };
}
