import React from 'react';
import { FormContainerComponent } from './form-container.styled';

interface FormContainerProps {
  children: React.ReactNode;
  hasSections?: boolean;
}

export function FormContainer({ children, hasSections = true }: FormContainerProps) {
  return (
    <FormContainerComponent
      $hasSections={hasSections}
      animate={{ opacity: 1 }}
      initial={{ opacity: 0 }}
      transition={{ duration: 0.2 }}
    >
      {children}
    </FormContainerComponent>
  );
}
