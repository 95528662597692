import { type Method } from 'axios';
import { methods, request } from '../lib';
import { type Note, type Client, type WalletItem } from '../types/client.types';
import { endpoints } from './endpoints';

export async function getClients(queryString?: string): Promise<Client[]> {
  const data = await request({
    endpoint: endpoints.clients.all(queryString),
    method: methods.GET as Method,
  });

  return data.data;
}

export async function getClient(clientId: string): Promise<Client> {
  const data = await request<Client>({
    endpoint: endpoints.clients.id(clientId),
    method: methods.GET as Method,
  });

  return data.data.data;
}

export async function postClient(body: Partial<Client>): Promise<void> {
  await request({
    endpoint: endpoints.clients.all(),
    method: methods.POST as Method,
    body,
  });
}

export async function patchClient(clientId: string, body: Partial<Client>): Promise<void> {
  await request({
    endpoint: endpoints.clients.id(clientId),
    method: methods.PATCH as Method,
    body,
  });
}

export async function deleteClient(clientId: string): Promise<void> {
  await request({
    endpoint: endpoints.clients.id(clientId),
    method: methods.DELETE as Method,
  });
}

export async function getClientNotes(clientId: string, queryString: string): Promise<Note[]> {
  const data = await request({
    endpoint: endpoints.clients.notes.all(clientId, queryString),
    method: methods.GET as Method,
  });

  return data.data;
}

export async function postClientNote(clientId: string, body: Partial<Note>): Promise<void> {
  await request({
    endpoint: endpoints.clients.notes.all(clientId),
    method: methods.POST as Method,
    body,
  });
}

export async function patchClientNote(clientId: string, noteId: string, body: Partial<Note>): Promise<void> {
  await request({
    endpoint: endpoints.clients.notes.id(clientId, noteId),
    method: methods.PATCH as Method,
    body,
  });
}

export async function deleteClientNote(clientId: string, noteId: string): Promise<void> {
  await request({
    endpoint: endpoints.clients.notes.id(clientId, noteId),
    method: methods.DELETE as Method,
  });
}

export async function getClientWallet(clientId: string, queryString: string): Promise<WalletItem[]> {
  const data = await request({
    endpoint: endpoints.clients.wallet.all(clientId, queryString),
    method: methods.GET as Method,
  });

  return data.data;
}

export async function getClientWalletItem(clientId: string, itemId: string, queryString: string): Promise<WalletItem> {
  const data = await request({
    endpoint: endpoints.clients.wallet.id(clientId, itemId, queryString),
    method: methods.GET as Method,
  });

  return data.data;
}
