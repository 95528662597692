import { outlineStyle } from '@pata-app/themes';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

interface SideNavigationItemProps {
  isSelected: boolean;
}

interface SideNavigationContainerProps {
  $showSubMenu: boolean;
  $hasSubMenu: boolean;
}

const MAIN_NAVIGATION_WIDTH = 74;
const SUB_MENU_WIDTH = 250;

export const SubMenuToggleButton = styled.button`
  position: absolute;
  top: 5px;
  right: 5px;

  display: flex;
  align-items: center;
  justify-content: center;

  background: none;

  border: none;

  height: 30px;
  width: 30px;

  border-radius: ${({ theme }) => theme.core.border.element.radius}px;

  ${outlineStyle}

  svg {
    fill: ${({ theme }) => theme.core.colors.greyscale.dark};
  }
`;

export const SubMenuContainer = styled.div`
  position: absolute;

  display: flex;
  flex-direction: column;

  width: ${SUB_MENU_WIDTH}px;
  height: 100%;

  padding: 35px 12px ${({ theme }) => theme.core.sizing[2]}px 12px;

  border-right: 1px solid ${({ theme }) => theme.core.colors.greyscale.medium};

  background: ${({ theme }) => theme.core.colors.greyscale.white};
`;

export const SideNavigationContainer = styled.nav<SideNavigationContainerProps>`
  position: relative;

  display: flex;

  min-width: ${({ $showSubMenu, $hasSubMenu }) =>
    $showSubMenu && $hasSubMenu ? MAIN_NAVIGATION_WIDTH + SUB_MENU_WIDTH : MAIN_NAVIGATION_WIDTH}px;

  transition: all 0.2s ease-in-out;

  ${SubMenuContainer} {
    left: ${({ $showSubMenu }) => ($showSubMenu ? MAIN_NAVIGATION_WIDTH : `-${SUB_MENU_WIDTH}`)}px;

    transition: all 0.2s ease-in-out;
  }

  ${SubMenuToggleButton} {
    right: ${({ $showSubMenu }) => ($showSubMenu ? 5 : -MAIN_NAVIGATION_WIDTH - 35)}px;

    svg {
      fill: ${({ theme }) => theme.core.colors.greyscale.black};

      transform: rotate(180deg);

      transition: transform 0.2s ease-in-out;
    }
  }
`;

export const MainNavigationContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${({ theme }) => theme.core.sizing[0]}px;

  padding: ${({ theme }) => theme.core.sizing[1]}px 0;

  width: ${MAIN_NAVIGATION_WIDTH}px;
  height: 100%;

  background: ${({ theme }) => theme.core.colors.greyscale.light};

  overflow-y: auto;
  overflow-x: hidden;

  z-index: 1;
`;

export const SideNavigationItem = styled(Link)<SideNavigationItemProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: ${({ theme }) => theme.core.sizing[1]}px;

  border: none;

  border-radius: 4px;

  margin: 4px;

  width: 50px;
  min-height: 50px;

  transition: all 0.2s ease-in-out;

  text-decoration: none;
  background: ${({ theme, isSelected }) => (isSelected ? theme.core.colors.primary.green.medium : 'transparent')};

  svg {
    fill: ${({ theme, isSelected }) =>
      isSelected ? theme.core.colors.greyscale.white : theme.core.colors.greyscale.black};
  }

  span {
    font-family: ${({ theme }) => theme.core.fontFamily.jost};
    font-size: ${({ theme }) => theme.core.fontSizes.s}px;
    font-weight: 600;
    color: ${({ theme, isSelected }) =>
      isSelected ? theme.core.colors.greyscale.white : theme.core.colors.greyscale.dark};
  }

  &:hover {
    background: ${({ theme, isSelected }) =>
      isSelected ? theme.core.colors.primary.green.medium : `${theme.core.colors.primary.green.medium}33`};
  }
`;
