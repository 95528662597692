import styled from 'styled-components';
import { motion } from 'framer-motion';
import { LinkButtonContainer } from '../../../../link-button/link-button.styled';

interface FormContainerComponentProps {
  $hasSections: boolean;
}

export const FormContainerComponent = styled(motion.div)<FormContainerComponentProps>`
  display: flex;
  flex-direction: column;

  gap: ${({ $hasSections, theme }) => ($hasSections ? undefined : `${theme.core.sizing[2]}px`)};

  ${LinkButtonContainer} {
    align-self: flex-end;
  }
`;
