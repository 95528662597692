import { getDateFromString } from '../../../lib/helpers/get-date-from-string';
import { type Schedule, type TimeRange } from '../../../types/member.types';

interface ClosedTime {
  start: Date;
  end: Date;
  resourceId: string;
  id: string;
  title: string;
}

const DAY_START = 0;
const DAY_END = 86399;

export function generateClosedTimes(
  shifts: TimeRange[],
  baseDate: string,
  resourceId: string,
  id: string,
  title: string,
): ClosedTime[] {
  const formattedDate = getDateFromString(baseDate);

  function addSecondsToBaseDate(seconds: number): Date {
    const date = new Date(formattedDate);
    date.setSeconds(date.getSeconds() + seconds);
    
    return date;
  }

  if (shifts.length === 0) {
    return [
      {
        start: addSecondsToBaseDate(DAY_START),
        end: addSecondsToBaseDate(DAY_END),
        resourceId,
        id,
        title,
      },
    ];
  }

  shifts.sort((a, b) => a.startTime - b.startTime);

  const closedTimes: ClosedTime[] = [];

  if (shifts[0].startTime > DAY_START) {
    closedTimes.push({
      start: addSecondsToBaseDate(DAY_START),
      end: addSecondsToBaseDate(shifts[0].startTime),
      resourceId,
      id,
      title,
    });
  }

  for (let i = 0; i < shifts.length - 1; i++) {
    closedTimes.push({
      start: addSecondsToBaseDate(shifts[i].endTime),
      end: addSecondsToBaseDate(shifts[i + 1].startTime),
      resourceId,
      id,
      title,
    });
  }

  if (shifts[shifts.length - 1].endTime < DAY_END) {
    closedTimes.push({
      start: addSecondsToBaseDate(shifts[shifts.length - 1].endTime),
      end: addSecondsToBaseDate(DAY_END),
      resourceId,
      id,
      title,
    });
  }

  return closedTimes;
}

export function generateBackgroundEvents(schedule: Schedule[]) {
  return schedule.reduce((backgroundEvents, scheduleMember) => {
    return [
      ...backgroundEvents,
      ...scheduleMember.schedules.reduce((acc, scheduleItem) => {
        return [
          ...acc,
          ...generateClosedTimes(
            scheduleItem.shift ?? [],
            scheduleItem.scheduleDay,
            scheduleMember.member.id ?? '',
            scheduleMember.member.id ?? '',
            'Not available',
          ),
        ];
      }, []),
    ];
  }, []);
}
