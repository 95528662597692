import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';

i18next
  .use(initReactI18next)
  .use(LanguageDetector)
  .use(Backend)
  .init({
    // debug: true,
    ns: [
      'common',
      'routes',
      'users',
      'clients',
      'team',
      'catalogue',
      'payments',
      'appointments',
      'sales',
      'onboarding',
    ],
    fallbackLng: 'en-GB',
    supportedLngs: ['en-GB'],
    backend: {
      loadPath: `${process.env.CDN_ASSETS_BASE_URL}locales/{{lng}}/{{ns}}.json`,
    },
  });
