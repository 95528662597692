import styled, { css } from 'styled-components';

interface TodayButtonProps {
  $highlight: boolean;
}

const buttonBaseStyles = css`
  display: flex;
  align-items: center;

  height: 45px;
  min-width: 50px;

  padding: ${({ theme }) => theme.core.sizing[1]}px;

  border-radius: 0;
  border: 0.5px solid ${({ theme }) => theme.core.colors.greyscale.medium};

  background: ${({ theme }) => theme.core.colors.greyscale.white};

  font-size: ${({ theme }) => theme.core.fontSizes.sm}px;

  cursor: pointer;
`;

export const DateNavigatorContainer = styled.div`
  display: flex;

  > div {
    > button {
      border-radius: 0;
      border-top: 0.5px solid ${({ theme }) => theme.core.colors.greyscale.medium};
      border-bottom: 0.5px solid ${({ theme }) => theme.core.colors.greyscale.medium};
    }
  }
`;

export const PreviousButton = styled.div`
  ${buttonBaseStyles}

  justify-content: flex-start;

  border-radius: ${({ theme }) => theme.core.border.element.radius}px 0 0
    ${({ theme }) => theme.core.border.element.radius}px;

  svg {
    fill: ${({ theme }) => theme.core.colors.greyscale.dark};
  }
`;

export const NextButton = styled.div`
  ${buttonBaseStyles}

  justify-content: flex-end;

  border-radius: 0 ${({ theme }) => theme.core.border.element.radius}px
    ${({ theme }) => theme.core.border.element.radius}px 0;

  svg {
    transform: rotate(-180deg);

    fill: ${({ theme }) => theme.core.colors.greyscale.dark};
  }
`;

export const CalendarButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  height: 43px;
  min-width: 200px;

  padding: ${({ theme }) => theme.core.sizing[1]}px;
`;

export const TodayButton = styled.button<TodayButtonProps>`
  ${buttonBaseStyles}

  background: ${({ theme, $highlight }) =>
    $highlight ? theme.core.colors.greyscale.light : theme.core.colors.greyscale.white};
  font-weight: ${({ theme, $highlight }) =>
    $highlight ? theme.core.fontWeight.medium : theme.core.fontWeight.regular};
`;
