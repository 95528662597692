import styled from 'styled-components';

export const MobileLayoutContainer = styled.div`
  display: flex;
  flex-direction: column;

  overflow-y: auto;

  height: 100%;

  -webkit-transform: translateZ(0);
`;

export const MobileMainContent = styled.main`
  flex-grow: 1;

  padding: 86px 16px 76px 16px;
  /* padding: ${({ theme }) => theme.core.sizing[2]}px; */

  -webkit-overflow-scrolling: touch;

  z-index: 1;

  > div {
    overflow-x: hidden;
  }
`;

export const MobileSubMenuContainer = styled.div`
  padding: ${({ theme }) => theme.core.sizing[2]}px;
`;

export const MobileContentContainer = styled.main``;
