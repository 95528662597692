import React, { Suspense } from 'react';
import { SideNavigation } from '../components/navigation/side-navigation/side-navigation';
import { Header } from '../components/header/header';
import { MainContentContainer } from '../components/main-content/main-content.styled';
import { DesktopLayoutContainer, DesktopMainContentContainer } from './desktop-layout.styled';

interface DesktopLayoutProps {
  children: React.ReactNode;
}

export function DesktopWrapper({ children }: DesktopLayoutProps): JSX.Element {
  return (
    <Suspense fallback={<></>}>
      <DesktopLayoutContainer>
        <Header />
        <DesktopMainContentContainer>
          <SideNavigation />
          <MainContentContainer>{children}</MainContentContainer>
        </DesktopMainContentContainer>
      </DesktopLayoutContainer>
    </Suspense>
  );
}
