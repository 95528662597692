import React from 'react';
import { AccountIconContainer } from './account-icon.styled';

interface AccountProps {
  initial: string;
}

export function AccountIcon({ initial }: AccountProps) {
  return <AccountIconContainer>{initial}</AccountIconContainer>;
}
