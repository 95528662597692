import styled from 'styled-components';

interface DesktopDropdownContainerProps {
  isOpen: boolean;
}

export const DesktopDropdownContainer = styled.div<DesktopDropdownContainerProps>`
  position: absolute;
  top: 60px;
  right: ${({ isOpen }) => (isOpen ? 0 : '-350px')};

  width: 300px;
  height: auto;

  background: ${({ theme }) => theme.core.colors.greyscale.white};
  border: 1px solid ${({ theme }) => theme.core.colors.greyscale.medium};

  border-radius: ${({ theme }) => theme.core.border.container.radius}px;

  box-shadow: rgb(0 0 0 / 28%) 0px 4px 10px;

  transition: all 0.3s ease-in-out;

  > div:last-of-type {
    margin: 0 ${({ theme }) => theme.core.sizing[2]}px;

    > a {
      height: 60px;

      font-size: 14px;

      svg {
        height: 20px;
      }
    }
  }
`;
