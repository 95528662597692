import styled from 'styled-components';

interface LoaderContainerProps {
  $addPadding: boolean;
}

export const LoaderContainer = styled.div<LoaderContainerProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;

  width: 100%;
  height: 100%;

  padding-top: ${({ $addPadding }) => $addPadding && 'calc(0.2 * 100vh)'};
`;
