import { useSearchParams } from 'react-router-dom';
import { DateTime } from 'luxon';
import { useEffect, useMemo } from 'react';
import { useGetWorkplaces } from '../../../../../queries/workplace.queries';
import { useGetMembers } from '../../../../../queries/member.queries';
import { useCalendarParams } from './use-calendar-params';
import { useSetSearchParamsAndLocalStorage } from './use-set-search-params-and-local-storage';

export function useCalendarFilters() {
  const { teamMemberId, workplaceId, viewMode, calendarDate } = useCalendarParams();
  const [searchParams, setSearchParams] = useSearchParams();

  const setSearchParamsAndLocalStorage = useSetSearchParamsAndLocalStorage(searchParams);

  const { data: workplaces } = useGetWorkplaces();
  const { data: members } = useGetMembers('members', '');

  const currentMemberWorkplaceIds = useMemo(() => {
    return members?.data?.find((member) => member.id === teamMemberId)?.workplaces.map((workplace) => workplace.id);
  }, [members?.data, teamMemberId]);

  const workplaceMembers = useMemo(() => {
    if (workplaceId)
      return members?.data
        ?.filter((member) => {
          return member.workplaces.map((workplace) => workplace.id).includes(workplaceId) && member.bookingAllowed;
        })
        .sort((a, b) => (a.firstName > b.firstName ? 1 : -1));
  }, [workplaceId, members?.data]);

  const workplaceOptions = useMemo(
    () =>
      workplaces?.map((workplace) => ({
        value: workplace.id,
        label: workplace.name,
      })),
    [workplaces],
  );

  const memberOptions = useMemo(
    () =>
      workplaceMembers?.map((member) => ({
        value: member.id,
        label: member.firstName,
      })),
    [workplaceMembers],
  );

  const memberOptionsWithAll = memberOptions ? [{ value: '', label: 'All' }, ...memberOptions] : [];

  const handleMemberSelect = (memberId: string) => {
    if (memberId !== '') {
      setSearchParamsAndLocalStorage('clr-mbr-id', memberId);
    } else {
      setSearchParamsAndLocalStorage('clr-mbr-id', '');

      setSearchParamsAndLocalStorage('clr-view', 'day');
    }

    setSearchParams(searchParams);
  };

  const handleWorkplaceSelect = (selectedWorkplaceId: string) => {
    setSearchParamsAndLocalStorage('clr-wpl-id', selectedWorkplaceId);

    if (!currentMemberWorkplaceIds?.includes(selectedWorkplaceId)) {
      setSearchParamsAndLocalStorage('clr-mbr-id', '');
      setSearchParamsAndLocalStorage('clr-view', 'day');
    }

    setSearchParams(searchParams);
  };

  const handleViewModeSelect = (updatedViewMode: string) => {
    setSearchParamsAndLocalStorage('clr-view', updatedViewMode);

    if (viewMode === 'day' && updatedViewMode === 'week') {
      setSearchParamsAndLocalStorage('clr-mbr-id', memberOptions ? memberOptions[0]?.value : '');
    }

    setSearchParams(searchParams);
  };

  useEffect(() => {
    const persistedViewMode = localStorage.getItem('clr-view');

    if (!viewMode && persistedViewMode) {
      searchParams.set('clr-view', persistedViewMode);

      setSearchParams(searchParams);
    }

    if (!persistedViewMode && !viewMode) {
      searchParams.set('clr-view', 'day');

      setSearchParams(searchParams);
    }

    if (!calendarDate) {
      searchParams.set('clr-date', DateTime.now().toISODate());

      setSearchParams(searchParams);
    }
  }, []);

  useEffect(() => {
    const persistedWorkplaceId = localStorage.getItem('clr-wpl-id');

    if (persistedWorkplaceId && !workplaceId) {
      searchParams.set('clr-wpl-id', persistedWorkplaceId);
    }

    if (!persistedWorkplaceId && !workplaceId && workplaces) {
      searchParams.set('clr-wpl-id', workplaces[0]?.id ?? '');
    }

    setSearchParams(searchParams);
  }, [workplaceId, workplaces, setSearchParams, searchParams]);

  useEffect(() => {
    const persistedMemberId = localStorage.getItem('clr-mbr-id');

    if (persistedMemberId && teamMemberId === null) {
      searchParams.set('clr-mbr-id', persistedMemberId);
    }

    if (!persistedMemberId && workplaceId && teamMemberId === null && members?.data) {
      searchParams.set('clr-mbr-id', '');
    }
    setSearchParams(searchParams);
  }, [members?.data, teamMemberId, workplaceId, workplaces]);

  return {
    workplaceOptions,
    memberOptionsWithAll,
    memberOptions,
    handleMemberSelect,
    handleViewModeSelect,
    handleWorkplaceSelect,
    workplaceMembers,
  };
}
