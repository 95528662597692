import { type UseQueryResult, useQuery, useMutation, type UseQueryOptions } from 'react-query';
import {
  getPaymentAccount,
  putPaymentProtection,
  postPaymentAccount,
  deletePaymentProtection,
} from '../api/payments.api';
import { type PaymentAccount } from '../types/payments.types';

export function useGetPaymentAccount(options?: UseQueryOptions<PaymentAccount, Error>): UseQueryResult<PaymentAccount> {
  return useQuery<PaymentAccount, Error>('paymentAccount', () => getPaymentAccount(), {
    staleTime: 100000,
    ...options,
  });
}

export const usePostPaymentAccount = () => {
  return useMutation('postPaymentAccount', {
    mutationFn: (bankAccount: string) => postPaymentAccount({ bankAccount }),
  });
};

export const usePutPaymentProtection = () => {
  return useMutation('putPaymentAccount', {
    mutationFn: ({ protectionType, protectionDetails }: { protectionType: string; protectionDetails: unknown }) =>
      putPaymentProtection(protectionType, protectionDetails),
  });
};

export const useDeletePaymentProtection = () => {
  return useMutation('deletePaymentAccount', {
    mutationFn: () => deletePaymentProtection(),
  });
};
