import React from 'react';
import { MenuItemContainer, MenuItemCount } from './menu-item.styled';

interface MenuItemComponentProps {
  path: string;
  label: string;
  count: number;
  icon: string;
  isSelected: boolean;
  onSelect: (id: string) => void;
}

export function MenuItem({ path, label, count, icon, onSelect, isSelected }: MenuItemComponentProps): JSX.Element {
  const handleSelect = (): void => {
    onSelect(path);
  };

  return (
    <li>
      <MenuItemContainer $isSelected={Boolean(isSelected)} onClick={handleSelect}>
        <span>{label}</span>
        {Boolean(count) && <MenuItemCount>{count}</MenuItemCount>}
      </MenuItemContainer>
    </li>
  );
}
