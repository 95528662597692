import { useCallback } from 'react';
import { usePatchAppointment } from '../../../../../queries/appointment.queries';
import { getSecondsFromStartOfDay } from '../../../../../lib/helpers/get-seconds-from-start-of-day';
import { type CalendarEvent } from '../../../../../types/appointments.types';
import { queryClient } from '../../../../../queries/query-client';

interface HandleMoveEventProps {
  event: CalendarEvent;
  start: Date;
  end: Date;
  resourceId: string;
}

interface HandleResizeEventProps {
  event: CalendarEvent;
  start: Date;
  end: Date;
}

export function useCalendarDndEvents(setCalendarEvents: (updatedEvent: React.SetStateAction<CalendarEvent[]>) => void) {
  const patchAppointmentMutation = usePatchAppointment();

  const handleMoveEvent = useCallback(
    ({ event, start, end, resourceId }: HandleMoveEventProps) => {
      setCalendarEvents((prev) => {
        const existing = prev.find((ev) => ev.id === event.id) ?? {};
        const filtered = prev.filter((ev) => ev.id !== event.id);
        return [
          ...filtered,
          {
            ...existing,
            start,
            end,
            resourceId,
          },
        ];
      });

      patchAppointmentMutation.mutate(
        {
          id: event.appointmentId,
          appointmentDetails: {
            appointmentItems: [
              {
                action: 'update',
                id: event.id,
                startTime:
                  start.getTime() === event.start.getTime()
                    ? undefined
                    : getSecondsFromStartOfDay(event.start) -
                      (getSecondsFromStartOfDay(event.end) - getSecondsFromStartOfDay(end)),
                endTime: end.getTime() === event.end.getTime() ? undefined : getSecondsFromStartOfDay(end),
                member:
                  resourceId !== event.resourceId
                    ? {
                        id: resourceId,
                      }
                    : undefined,
              },
            ],
          },
        },
        {
          onSuccess: async () => {
            await queryClient.invalidateQueries('appointments');
            await queryClient.invalidateQueries(['appointment', event.appointmentId]);
          },
        },
      );
    },
    [setCalendarEvents, patchAppointmentMutation],
  );

  const handleResizeEvent = useCallback(
    ({ event, start, end }: HandleResizeEventProps) => {
      setCalendarEvents((prev) => {
        const existing = prev.find((ev) => ev.id === event.id) ?? {};
        const filtered = prev.filter((ev) => ev.id !== event.id);
        return [...filtered, { ...existing, start, end }];
      });

      patchAppointmentMutation.mutate(
        {
          id: event.appointmentId,
          appointmentDetails: {
            appointmentItems: [
              {
                action: 'update',
                id: event.id,
                startTime: start.getTime() === event.start.getTime() ? undefined : getSecondsFromStartOfDay(start),
                endTime: end.getTime() === event.end.getTime() ? undefined : getSecondsFromStartOfDay(end),
              },
            ],
          },
        },
        {
          onSuccess: async () => {
            // await queryClient.invalidateQueries('appointments');
            await queryClient.invalidateQueries(['appointment', event.appointmentId]);
          },
        },
      );
    },
    [setCalendarEvents, patchAppointmentMutation],
  );
  return { handleMoveEvent, handleResizeEvent };
}
