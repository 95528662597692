import styled from 'styled-components';
import { outlineStyle } from '@pata-app/themes';

export const AccountContainer = styled.div`
  position: relative;
`;

export const AccountButton = styled.button`
  padding: 0;

  width: 40px;

  border: none;

  background: none;
  border-radius: ${({ theme }) => theme.core.border.element.radius}px;

  ${outlineStyle}

  img {
    width: 100%;

    object-fit: cover;

    border-radius: 50%;
  }
`;
