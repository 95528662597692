import { type Method } from 'axios';
import { methods, request } from '../lib';
import { type Schedule, type Member, type ScheduledShift, type TimeOff } from '../types/member.types';
import { endpoints } from './endpoints';

export async function getMembers(queryString?: string): Promise<Member[]> {
  const data = await request({
    endpoint: endpoints.members.all(queryString),
    method: methods.GET as Method,
  });

  return data.data;
}

export async function getMembersSchedule(queryString?: string): Promise<Schedule[]> {
  const data = await request({
    endpoint: endpoints.members.schedule.all(queryString),
    method: methods.GET as Method,
  });

  return data.data;
}

export async function getMember(memberId: string): Promise<Member> {
  const data = await request<Member>({
    endpoint: endpoints.members.id(memberId),
    method: methods.GET as Method,
  });

  return data.data.data;
}

export async function postMember(body: Partial<Member>): Promise<void> {
  await request({
    endpoint: endpoints.members.all(),
    method: methods.POST as Method,
    body,
  });
}

export async function patchMember(memberId: string, body: Partial<Member>): Promise<void> {
  await request({
    endpoint: endpoints.members.id(memberId),
    method: methods.PATCH as Method,
    body,
  });
}

export async function deleteMember(memberId: string): Promise<void> {
  await request({
    endpoint: endpoints.members.id(memberId),
    method: methods.DELETE as Method,
  });
}

export async function putShift(memberId: string, body: ScheduledShift): Promise<void> {
  await request({
    endpoint: endpoints.members.shifts(memberId),
    method: methods.PUT as Method,
    body,
  });
}

export async function getTimeOff(memberId: string, timeOffId: string): Promise<TimeOff> {
  const data = await request<TimeOff>({
    endpoint: endpoints.members.timeOff.id(memberId, timeOffId),
    method: methods.GET as Method,
  });

  return data.data.data;
}

export async function postTimeOff(memberId: string, body: Partial<TimeOff>): Promise<void> {
  await request({
    endpoint: endpoints.members.timeOff.all(memberId),
    method: methods.POST as Method,
    body,
  });
}

export async function patchTimeOff(memberId: string, timeOffId: string, body: Partial<Member>): Promise<void> {
  await request({
    endpoint: endpoints.members.timeOff.id(memberId, timeOffId),
    method: methods.PATCH as Method,
    body,
  });
}

export async function deleteTimeOff(memberId: string, timeOffId: string): Promise<void> {
  await request({
    endpoint: endpoints.members.timeOff.id(memberId, timeOffId),
    method: methods.DELETE as Method,
  });
}
