import React from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export function ToastNotifications() {
  return (
    <ToastContainer
      autoClose={5000}
      closeOnClick
      hideProgressBar
      newestOnTop={false}
      pauseOnFocusLoss
      pauseOnHover
      position="bottom-right"
      rtl={false}
      theme="dark"
    />
  );
}
