import { type Method } from 'axios';
import { methods, request } from '../lib';
import { type ServiceCategory, type Service, type Membership } from '../types/catalogue.types';
import { endpoints } from './endpoints';

export async function getServices(queryString?: string): Promise<Service[]> {
  const data = await request({
    endpoint: endpoints.catalogue.services.all(queryString),
    method: methods.GET as Method,
  });

  return data.data;
}

export async function getService(serviceId: string): Promise<Service> {
  const data = await request<Service>({
    endpoint: endpoints.catalogue.services.id(serviceId),
    method: methods.GET as Method,
  });

  return data.data.data;
}

export async function postService(body: Partial<Service>): Promise<void> {
  await request({
    endpoint: endpoints.catalogue.services.all(),
    method: methods.POST as Method,
    body,
  });
}

export async function patchService(serviceId: string, body: Partial<Service>): Promise<void> {
  await request({
    endpoint: endpoints.catalogue.services.id(serviceId),
    method: methods.PATCH as Method,
    body,
  });
}

export async function deleteService(serviceId: string): Promise<void> {
  await request({
    endpoint: endpoints.catalogue.services.id(serviceId),
    method: methods.DELETE as Method,
  });
}

export async function getServiceCategories(queryString: string): Promise<ServiceCategory[]> {
  const data = await request({
    endpoint: endpoints.catalogue.categories.all(queryString),
    method: methods.GET as Method,
  });

  return data.data;
}

export async function getServiceCategory(serviceCategoryId: string): Promise<ServiceCategory> {
  const data = await request<ServiceCategory>({
    endpoint: endpoints.catalogue.categories.id(serviceCategoryId),
    method: methods.GET as Method,
  });

  return data.data.data;
}

export async function postServiceCategory(body: Partial<ServiceCategory>): Promise<void> {
  await request({
    endpoint: endpoints.catalogue.categories.all(),
    method: methods.POST as Method,
    body,
  });
}

export async function patchServiceCategory(serviceCategoryId: string, body: Partial<ServiceCategory>): Promise<void> {
  await request({
    endpoint: endpoints.catalogue.categories.id(serviceCategoryId),
    method: methods.PATCH as Method,
    body,
  });
}

export async function deleteServiceCategory(serviceCategoryId: string): Promise<void> {
  await request({
    endpoint: endpoints.catalogue.categories.id(serviceCategoryId),
    method: methods.DELETE as Method,
  });
}

export async function getMemberships(queryString: string): Promise<Membership[]> {
  const data = await request({
    endpoint: endpoints.catalogue.memberships.all(queryString),
    method: methods.GET as Method,
  });

  return data.data;
}

export async function getMembership(membershipId: string): Promise<Membership> {
  const data = await request<Membership>({
    endpoint: endpoints.catalogue.memberships.id(membershipId),
    method: methods.GET as Method,
  });

  return data.data.data;
}

export async function postMembership(body: Partial<Membership>): Promise<void> {
  await request({
    endpoint: endpoints.catalogue.memberships.all(),
    method: methods.POST as Method,
    body,
  });
}

export async function patchMembership(membershipId: string, body: Partial<Membership>): Promise<void> {
  await request({
    endpoint: endpoints.catalogue.memberships.id(membershipId),
    method: methods.PATCH as Method,
    body,
  });
}

export async function deleteMembership(membershipId: string): Promise<void> {
  await request({
    endpoint: endpoints.catalogue.memberships.id(membershipId),
    method: methods.DELETE as Method,
  });
}
