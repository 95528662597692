import './sentry/instrument';

import React from 'react';
import { createRoot } from 'react-dom/client';
import { App } from './app';

import './i18n/i18n';

const root = createRoot(document.getElementById('root') as Element);

root.render(<App />);
