export function formatSeconds(seconds: number, defaultUnit = 'hours', shortFormat = false) {
  if (seconds === 0) {
    return `0 ${defaultUnit}`;
  }
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);

  const parts = [];

  if (hours > 0) {
    parts.push(`${hours} hour${hours !== 1 ? 's' : ''}`);
  }

  if (minutes > 0 || hours === 0) {
    parts.push(`${minutes} ${shortFormat ? 'min' : 'minute'}${minutes !== 1 ? 's' : ''}`);
  }
  if (hours === 0 && minutes === 0) {
    parts.push(`${seconds} ${shortFormat ? 'sec' : 'second'}${seconds !== 1 ? 's' : ''}`);
  }

  return parts.join(' ');
}
