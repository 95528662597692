import styled from 'styled-components';

export const CalendarHeaderContainer = styled.div`
  padding: 0 0 ${({ theme }) => theme.core.sizing[4]}px 0;

  z-index: 2;
`;

export const DesktopHeaderContainer = styled.div`
  display: none;

  ${({ theme }) => theme.breakpoints.up('sm')} {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: ${({ theme }) => theme.core.sizing[2]}px;
  }
`;

export const MobileHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${({ theme }) => theme.breakpoints.up('sm')} {
    display: none;
  }
`;

export const CalendarPopupContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  > div:first-of-type {
    z-index: 2;
  }
`;

export const FiltersButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  height: 45px;
  width: 45px;

  border: 1px solid ${({ theme }) => theme.core.colors.greyscale.medium};
  border-radius: ${({ theme }) => theme.core.border.element.radius}px;

  background: none;

  svg {
    fill: ${({ theme }) => theme.core.colors.greyscale.dark};
  }
`;

export const CalendarViewButton = styled.button`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.core.sizing[1]}px;

  border: none;

  background: none;

  font-weight: ${({ theme }) => theme.core.fontWeight.semiBold};

  svg {
    transform: rotate(-90deg);
  }
`;

export const FiltersContainer = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.core.sizing[2]}px;
`;
