import { type Method } from 'axios';
import { methods, request } from '../lib';
import { type Note } from '../types/common.types';
import { type Appointment } from '../types/appointments.types';
import { endpoints } from './endpoints';

export async function getAppointments(queryString: string): Promise<Appointment[]> {
  const data = await request({
    endpoint: endpoints.appointment.all(queryString),
    method: methods.GET as Method,
  });

  return data.data;
}

export async function getAppointment(appointmentId: string): Promise<Appointment> {
  const data = await request<Appointment>({
    endpoint: endpoints.appointment.id(appointmentId),
    method: methods.GET as Method,
  });

  return data.data.data;
}

export async function postAppointment(body: Partial<Appointment>): Promise<Appointment> {
  const response = await request({
    endpoint: endpoints.appointment.all(),
    method: methods.POST as Method,
    body,
  });

  return response;
}

export async function patchAppointment(appointmentId: string, body: Partial<Appointment>): Promise<void> {
  await request({
    endpoint: endpoints.appointment.id(appointmentId),
    method: methods.PATCH as Method,
    body,
  });
}

export async function deleteAppointment(appointmentId: string): Promise<void> {
  await request({
    endpoint: endpoints.appointment.id(appointmentId),
    method: methods.DELETE as Method,
  });
}

export async function getAppointmentNotes(appointmentId: string, queryString: string): Promise<Note[]> {
  const data = await request({
    endpoint: endpoints.appointment.notes.all(appointmentId, queryString),
    method: methods.GET as Method,
  });

  return data.data;
}

export async function postAppointmentNote(appointmentId: string, body: Partial<Note>): Promise<void> {
  await request({
    endpoint: endpoints.appointment.notes.all(appointmentId),
    method: methods.POST as Method,
    body,
  });
}

export async function patchAppointmentNote(appointmentId: string, noteId: string, body: Partial<Note>): Promise<void> {
  await request({
    endpoint: endpoints.appointment.notes.id(appointmentId, noteId),
    method: methods.PATCH as Method,
    body,
  });
}
export async function postAppointmentPayment(appointmentId: string, body: Partial<any>): Promise<void> {
  await request({
    endpoint: endpoints.appointment.payments.all(appointmentId),
    method: methods.POST as Method,
    body,
  });
}

export async function deleteAppointmentNote(appointmentId: string, noteId: string): Promise<void> {
  await request({
    endpoint: endpoints.appointment.notes.id(appointmentId, noteId),
    method: methods.DELETE as Method,
  });
}
