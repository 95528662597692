import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const AccountOptionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  
`;

export const ProfileLink = styled(Link)``;

export const LogoutLink = styled(Link)``;
