export function removeDuplicateWords(input: string) {
  if (typeof input !== 'string') {
    return '';
  }

  // Normalize input: Trim spaces and detect the delimiter
  const delimiter = input.includes(', ') ? ', ' : input.includes(' - ') ? ' - ' : null;

  if (!delimiter) {
    // If no recognized delimiter, return the input as is
    return input.trim();
  }

  // Split the input into parts based on the delimiter
  const parts = input.split(delimiter).map((part) => part.trim());

  // Determine if the string is mirrored
  const midIndex = Math.floor(parts.length / 2);
  const firstHalf = parts.slice(0, midIndex);
  const secondHalf = parts.slice(parts.length - midIndex);

  if (firstHalf.join(' ') === secondHalf.reverse().join(' ')) {
    // If mirrored, return only the first half joined with the delimiter
    return firstHalf.join(` ${delimiter.trim()} `);
  }

  // If not mirrored, return the original string
  return input.trim();
}
