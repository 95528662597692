import { type Method } from 'axios';
import { methods, request } from '../lib';
import { type Terminal, type TerminalRequest, type TerminalRequestResponse } from '../types/payments.types';
import { endpoints } from './endpoints';

export async function getTerminals(): Promise<Terminal[]> {
  try {
    const data = await request({
      endpoint: endpoints.terminals.all,
      method: methods.GET as Method,
    });

    return data?.data?.data;
  } catch (error) {
    throw Error();
  }
}

export async function getTerminal(id: string): Promise<Terminal> {
  try {
    const data = await request({
      endpoint: endpoints.terminals.id(id),
      method: methods.GET as Method,
    });

    return data?.data?.data;
  } catch (error) {
    throw Error();
  }
}

export async function postTerminal(body: TerminalRequest): Promise<TerminalRequestResponse> {
  const data = await request({
    endpoint: endpoints.terminals.all,
    method: methods.POST as Method,
    body,
  });

  return data.data.data;
}

export async function patchTerminal(
  terminalId: string,
  terminalDetails: { name: string; workplaces: string[] },
): Promise<Terminal> {
  const data = await request({
    endpoint: endpoints.terminals.id(terminalId),
    method: methods.PATCH as Method,
    body: terminalDetails,
  });

  return data.data.data;
}

export async function deletePaymentTerminal(id: string) {
  await request({
    endpoint: endpoints.terminals.id(id),
    method: methods.DELETE as Method,
  });
}
