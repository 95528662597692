import styled, { css } from 'styled-components';
import { motion } from 'framer-motion';
import { ColourNames, ColourNamesCalenderEvents } from '../../../../config/colours';

const colourCss = css`
  ${Object.keys(ColourNamesCalenderEvents).map(
    (colour) =>
      `&.${ColourNamesCalenderEvents[colour]} {
      background: ${colour};
    }`,
  )}
`;

export const CalendarContainer = styled(motion.div)`
  min-height: fit-content;

  z-index: 1;

  .rbc-timeslot-group {
    z-index: 1;
  }

  .rbc-time-slot {
    min-height: 20px;

    transition: 0.1s all ease-in-out;
    transition-delay: 0.1s;

    &:hover {
      background: ${({ theme }) => theme.core.colors.primary.green.medium}4D;

      cursor: pointer;
    }
  }

  .rbc-event {
    z-index: 2;
  }

  .rbc-event-disabled {
    pointer-events: none;
    opacity: 0.7;
  }

  .rbc-events-container:hover {
    cursor: pointer;
  }

  .rbc-label {
    font-weight: ${({ theme }) => theme.core.fontWeight.medium};
    font-size: ${({ theme }) => theme.core.fontSizes.sm}px;
  }

  .rbc-current-time-indicator {
    height: 2px;

    background-color: ${({ theme }) => theme.core.colors.primary.green.medium};
  }

  .rbc-events-container:has(.rbc-background-event) {
    margin-right: 0;
  }

  .rbc-background-event {
    border-radius: 0;

    background: black;
    opacity: 0.2;

    .rbc-event-label,
    .rbc-event-content {
      display: none;
    }
  }

  .rbc-header {
    display: flex;
    align-items: center;
    justify-content: center;

    min-height: 50px;

    span {
      font-weight: ${({ theme }) => theme.core.fontWeight.medium};
      font-size: ${({ theme }) => theme.core.fontSizes.sm}px;
    }
  }

  .rbc-allday-cell {
    display: none;
  }

  .rbc-header {
    border: none;
  }

  .rbc-time-gutter .rbc-timeslot-group {
    border: none;
  }

  .rbc-event {
    border: 1px solid ${({ theme }) => theme.core.colors.greyscale.white};

    color: ${({ theme }) => theme.core.colors.greyscale.black};
    font-size: ${({ theme }) => theme.core.fontSizes.sm}px;

    transition: 0.2s border ease-in-out;

    &:hover {
      border: 2px solid ${({ theme }) => theme.core.colors.primary.green.medium};
    }

    ${colourCss}
  }

  .rbc-background-event {
    opacity: 0.05;
  }
`;
