import { type UseQueryResult, useQuery, useMutation, type UseQueryOptions, type QueryKey } from 'react-query';
import { type AppointmentNotesSearch, type Appointment, type AppointmentSearch } from '../types/appointments.types';
import {
  deleteAppointment,
  deleteAppointmentNote,
  getAppointment,
  getAppointmentNotes,
  getAppointments,
  patchAppointment,
  patchAppointmentNote,
  postAppointment,
  postAppointmentNote,
  postAppointmentPayment,
} from '../api/appointment.api';
import { type Note } from '../types/common.types';
import { useGetUser } from './user.queries';

export function useGetAppointments(
  queryKey: QueryKey,
  queryString: URLSearchParams,
  options?: UseQueryOptions<AppointmentSearch, Error>,
): UseQueryResult<AppointmentSearch> {
  const { data } = useGetUser();

  const accountId = data?.accounts?.[0];

  return useQuery<AppointmentSearch, Error>(queryKey, () => getAppointments(queryString), {
    enabled: Boolean(accountId),
    ...options,
  });
}

export function useGetAppointmentsSales(
  queryKey: QueryKey,
  queryString: URLSearchParams,
  options?: UseQueryOptions<AppointmentSearch, Error>,
): UseQueryResult<AppointmentSearch> {
  const { data } = useGetUser();

  const accountId = data?.accounts[0];

  return useQuery<AppointmentSearch, Error>('salesAppointments', () => getAppointments(queryString), {
    enabled: Boolean(accountId),
    select: (data: Appointment[]) => {
      const formattedData = data.data.reduce((appointmentItemList, appointment) => {
        return [
          ...appointmentItemList,
          ...appointment.appointmentItems.map((item) => ({
            ...item,
            status: appointment.status,
            workplace: appointment.workplace,
            date: appointment.date,
            pet: appointment.pet,
          })),
        ];
      }, []);

      return formattedData;
    },
  });
}

export function useGetAppointment(
  appointmentId: string | null,
  options?: UseQueryOptions<Appointment, Error>,
): UseQueryResult<Appointment> {
  return useQuery<Appointment, Error>(['appointment', appointmentId], () => getAppointment(appointmentId as string), {
    staleTime: 100000,
    enabled: Boolean(appointmentId),
    ...options,
  });
}

export const usePatchAppointment = () => {
  return useMutation('patchAppointment', {
    mutationFn: (data: { id: string; appointmentDetails: Partial<Appointment> }) =>
      patchAppointment(data.id, data.appointmentDetails),
  });
};

export const useDeleteAppointment = () => {
  return useMutation('deleteAppointment', {
    mutationFn: (id: string) => deleteAppointment(id),
  });
};

export const usePostAppointment = () => {
  return useMutation('postAppointment', {
    mutationFn: (appointmentDetails: Partial<Appointment>) => postAppointment(appointmentDetails),
  });
};

export function useGetAppointmentNotes(
  appointmentId: string,
  queryKey: QueryKey,
  queryString?: string,
  options?: UseQueryOptions<AppointmentNotesSearch, Error>,
): UseQueryResult<AppointmentNotesSearch> {
  const { data } = useGetUser();

  const accountId = data?.accounts[0];

  return useQuery<AppointmentNotesSearch, Error>(queryKey, () => getAppointmentNotes(appointmentId, queryString), {
    enabled: Boolean(accountId) && Boolean(appointmentId),
    ...options,
  });
}

export const usePatchAppointmentNote = () => {
  return useMutation('patchAppointmentNote', {
    mutationFn: (data: { appointmentId: string; noteId: string; noteDetails: Partial<Note> }) =>
      patchAppointmentNote(data.appointmentId, data.noteId, data.noteDetails),
  });
};

export const usePostAppointmentPayment = () => {
  return useMutation('putAppointmentPayment', {
    mutationFn: (data: { appointmentId: string; paymentDetails: Partial<any> }) =>
      postAppointmentPayment(data.appointmentId, data.paymentDetails),
  });
};

export const useDeleteAppointmentNote = () => {
  return useMutation('deleteAppointmentNote', {
    mutationFn: (data: { appointmentId: string; noteId: string }) =>
      deleteAppointmentNote(data.appointmentId, data.noteId),
  });
};

export const usePostAppointmentNote = () => {
  return useMutation('postAppointmentNote', {
    mutationFn: (data: { appointmentId: string; noteDetails: Partial<Note> }) =>
      postAppointmentNote(data.appointmentId, data.noteDetails),
  });
};
