import styled from 'styled-components';

export const AuthLayoutContainer = styled.main`
  display: flex;
  justify-content: center;

  min-height: 100dvh;
  max-height: 100dvh;
  width: 100dvw;
`;

export const AuthLayoutSection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  flex-shrink: 0;

  width: 100%;
  min-height: 100dvh;

  padding: 30px;

  img {
    width: 150px;
  }

  h1 {
    margin: 80px 0;
  }

  @media ${({ theme }) => theme.core.breakpoints.s} {
    max-width: 500px;
  }

  @media ${({ theme }) => theme.core.breakpoints.l} {
    position: relative;

    padding: 60px;

    width: 500px;
    min-width: 300px;
    max-width: 550px;

    overflow-y: auto;
  }
`;

export const AuthLayoutSide = styled.aside`
  display: none;

  @media ${({ theme }) => theme.core.breakpoints.l} {
    flex-grow: 2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    height: 100%;

    overflow: hidden;

    background: ${({ theme }) => theme.core.colors.primary.teal};

    picture {
      height: 100dvh;
      width: 100%;
    }

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }

    h2,
    h3 {
      width: 500px;
      text-align: center;
      color: white;
    }
  }
`;
