import { DateTime } from 'luxon';

export function getWeekRange(dateString: string) {
  const date = DateTime.fromISO(dateString);

  const startOfWeek = date.startOf('week').startOf('day');
  const endOfWeek = date.endOf('week').endOf('day');

  const formattedStartOfWeek = startOfWeek.toFormat('d LLL yyyy');
  const formattedEndOfWeek = endOfWeek.toFormat('d LLL yyyy');

  const weekRange = `${formattedStartOfWeek} - ${formattedEndOfWeek}`;

  return weekRange;
}
