import styled from 'styled-components';

export const DesktopLayoutContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100dvh;
  width: 100dvw;

  overflow: hidden;
`;

export const DesktopMainContentContainer = styled.div`
  display: flex;
  flex-grow: 1;
  flex-shrink: 1;

  height: calc(100% - 70px);

  overflow-x: hidden;
`;
