import React from 'react';
import { CalendarHeaderContainer } from './calendar-header.styled';
import { CalendarHeaderMobile } from './calendar-header-mobile';
import { CalendarHeaderDesktop } from './calendar-header-desktop';

export function CalendarHeader() {
  return (
    <CalendarHeaderContainer>
      <CalendarHeaderMobile />

      <CalendarHeaderDesktop />
    </CalendarHeaderContainer>
  );
}
