import React, { type RefObject, useRef, useState } from 'react';
import { useClickOutside } from '@pata-app/hooks';
import { useGetUser } from '../../../../../../../queries/user.queries';
import { Image } from '../../../../../../image/image';
import { AccountContainer, AccountButton } from './account-header.styled';
import { AccountMenu } from './components/account-menu/account-menu';
import { AccountHeaderContext } from './account-header.context';
import { AccountIcon } from './components/account-icon/account-icon';

export function AccountHeader(): JSX.Element {
  const [showAccountMenu, setShowAccountMenu] = useState(false);
  const { data, isLoading } = useGetUser();

  const containerRef = useRef<RefObject<HTMLDivElement> | null>(null);

  useClickOutside(containerRef as RefObject<HTMLDivElement>, () => {
    setShowAccountMenu(false);
  });

  const handleToggleAccountMenu = () => {
    setShowAccountMenu((currentToggle) => !currentToggle);
  };

  return (
    <AccountHeaderContext.Provider value={{ showAccountMenu, setShowAccountMenu }}>
      <AccountContainer ref={containerRef as RefObject<HTMLDivElement>}>
        <>
          <AccountButton onClick={handleToggleAccountMenu}>
            {Boolean(!isLoading) && (
              <>
                {data?.profilePicture ? (
                  <Image
                    alt={`${data.firstName} ${data.lastName}`}
                    excludeCdn
                    src={
                      data.userType === 'google'
                        ? data.profilePicture
                        : `${process.env.CDN_BASE_URL_USER_CONTENT}/${data.profilePicture}`
                    }
                  />
                ) : (
                  <AccountIcon initial={data?.firstName[0] ?? '-'} />
                )}
              </>
            )}
          </AccountButton>

          <AccountMenu />
        </>
      </AccountContainer>
    </AccountHeaderContext.Provider>
  );
}
