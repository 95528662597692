import React from 'react';
import { Image } from '../image/image';
import { getColourBasedOnString } from '../../lib/helpers/get-colour-based-on-string';
import { ColourNames } from '../../config/colours';
import { ProfileIconContainer, ProfileIconPlaceholder } from './profile-icon.styled';

export type PlaceholderTypes = 'solid' | 'outline';

interface ProfileIconProps {
  firstName: string;
  lastName: string;
  profilePicture?: string;
  size?: number;
  colour?: string;
  placeholderType?: PlaceholderTypes;
  excludeCdn: boolean;
}

export function ProfileIcon({
  firstName,
  lastName,
  profilePicture,
  colour,
  size = 50,
  placeholderType = 'solid',
  excludeCdn = false,
}: ProfileIconProps) {
  const profileColour = colour ?? getColourBasedOnString(firstName, Object.keys(ColourNames));
  return (
    <ProfileIconContainer $colour={profileColour} $placeholderType={placeholderType} $size={size}>
      {profilePicture ? (
        <Image
          excludeCdn={excludeCdn}
          src={excludeCdn ? profilePicture : `${process.env.CDN_BASE_URL_USER_CONTENT}/${profilePicture}`}
        />
      ) : (
        <ProfileIconPlaceholder>{`${firstName?.charAt(0)}${lastName?.charAt(0)}`}</ProfileIconPlaceholder>
      )}
    </ProfileIconContainer>
  );
}
