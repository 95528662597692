import React from 'react';
import { ErrorScreenContainer } from './error-screen.styled';
import { Image } from '../image/image';

export function ErrorScreen() {
  return (
    <ErrorScreenContainer>
      <Image
        alt="Illustration of dog digging"
        src={`${process.env.CDN_BASE_URL}/images/illustration-dog-digging.png`}
      />
      <h1>{"We're sorry, something went wrong."}</h1>
      <p>This issue has been reported.</p>
      <a href="/">Return Home</a>
    </ErrorScreenContainer>
  );
}
