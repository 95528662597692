import styled from 'styled-components';

export const ResourceContainerContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: ${({ theme }) => theme.core.sizing[0]}px;

  padding: ${({ theme }) => theme.core.sizing[1]}px 0;

  font-size: ${({ theme }) => theme.core.fontSizes.m}px;
  font-weight: ${({ theme }) => theme.core.fontWeight.medium};
`;
