import styled from 'styled-components';

export const AccountIconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  height: 40px;
  width: 40px;

  padding: 0;

  border-radius: 20px;
  border: 2px solid ${({ theme }) => theme.core.colors.greyscale.white};

  background: ${({ theme }) => theme.core.colors.primary.green.medium};
  color: ${({ theme }) => theme.core.colors.greyscale.white};

  font-weight: ${({ theme }) => theme.core.fontWeight.semiBold};
  font-size: ${({ theme }) => theme.core.fontSizes.xl}px;
`;
