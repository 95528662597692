export function getSecondsFromStartOfDay(date: Date) {
  if (!(date instanceof Date)) {
    throw new Error('Invalid Date object');
  }

  const hours = date.getHours();
  const minutes = date.getMinutes();
  const seconds = date.getSeconds();

  return hours * 3600 + minutes * 60 + seconds;
}
