import React from 'react';
import { Icon } from '../../../../../icon/icon';
import { navigationConfig } from '../routes.config';
import { useMenuPath } from '../hooks/use-menu-path';
import { MobileNavigationContainer, QuickLink } from './mobile-navigation.styled';

export function MobileNavigaion(): JSX.Element {
  const { currentPrimaryRoute } = useMenuPath();
  const quickLinkNavigationItems = navigationConfig.filter((item) => item.quickLink);

  return (
    <MobileNavigationContainer id="mobile-nav-container">
      <>
        {quickLinkNavigationItems.map((quickLink) => (
          <QuickLink $isSelected={quickLink.path === currentPrimaryRoute} key={quickLink.path} to={quickLink.path}>
            <Icon size="25px" type={quickLink.icon} />
          </QuickLink>
        ))}
        <QuickLink to="menu">
          <Icon size="25px" type="ActionMenu" />
        </QuickLink>
      </>
    </MobileNavigationContainer>
  );
}
