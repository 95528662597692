import React from 'react';
import { useTranslation } from 'react-i18next';
import { MenuItem } from '../../../../../../navigation/components/menu-item/menu-item';
import { useAccountHeaderContext } from '../../../account-header.context';
import { AccountOptionsContainer } from './account-menu-options.styled';

export function AccountMenuOptions() {
  const { setShowAccountMenu } = useAccountHeaderContext();

  const { t } = useTranslation();

  const handleCloseMenu = () => {
    setShowAccountMenu(false);
  };

  return (
    <AccountOptionsContainer>
      <MenuItem label={t('profile', { ns: 'routes' })} onClick={handleCloseMenu} path="user/profile" />
      <MenuItem label={t('logout', { ns: 'common' })} onClick={handleCloseMenu} path="logout" />
    </AccountOptionsContainer>
  );
}
