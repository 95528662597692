import React, { useCallback, useEffect, useMemo } from 'react';
import { DateTime } from 'luxon';
import { createSearchParams, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Loader } from '../../components/loader/loader';
import { useGetWorkplaces } from '../../queries/workplace.queries';
import { getSecondsFromStartOfDay } from '../../lib/helpers/get-seconds-from-start-of-day';
import { useNavigateWithSearchParams } from '../../hooks/use-navigate-with-search-params';
import { useGetMembers } from '../../queries/member.queries';
import { useCalendarFilters } from './components/calendar/hooks/use-calendar-filters';
import { CalendarComponent } from './components/calendar/calendar';
import { CalendarHeader } from './components/calendar/calendar-header/calendar-header';
import { useCalendarParams } from './components/calendar/hooks/use-calendar-params';
import { useBackgroundEvents } from './hooks/use-background-events';
import { useAppointmentEvents } from './hooks/use-appointment-events';
import { NewCalendarItemNotification } from './components/new-calendar-item-notification/new-calendar-item-notification';
import { AddEditBlockTime } from './components/add-edit-block-time/add-edit-block-time';

export function CalendarContent(): JSX.Element {
  const navigate = useNavigate();
  const navigateWithSearchParams = useNavigateWithSearchParams();
  const { addType, action } = useParams();

  const [searchParams] = useSearchParams();

  const { teamMemberId, workplaceId, viewMode, calendarDate } = useCalendarParams();

  const { workplaceMembers } = useCalendarFilters();

  const { isLoading: workplacesLoading } = useGetWorkplaces();
  const { isLoading: membersLoading } = useGetMembers('members', '');

  const { backgroundEvents, timeOffEvents } = useBackgroundEvents();

  const { appointments, isLoading: isLoadingAppointments } = useAppointmentEvents();


  const showingWorkplaceCalendarMembers = useMemo(
    () => (teamMemberId ? workplaceMembers?.filter((member) => member.id === teamMemberId) : workplaceMembers),
    [workplaceMembers, teamMemberId],
  );

  const handleSelectAppointmentTime = useCallback(
    (startTime: Date, calenderTeamMemberId?: string) => {
      if (addType === 'block-time') {
        navigateWithSearchParams('/calendar/block-time/add', undefined, {
          'blt-mbr-id': calenderTeamMemberId,
          'blt-start-date': DateTime.fromJSDate(startTime).toISODate(),
          'blt-start-time': getSecondsFromStartOfDay(startTime).toString(),
        });
      } else {
        navigate({
          pathname: '/calendar/panel/appointment/new/pet',
          search: createSearchParams({
            ...Object.fromEntries([...searchParams]),
            'apt-date': DateTime.fromJSDate(startTime).toISODate(),
            'apt-start-time': getSecondsFromStartOfDay(startTime).toString(),
            'apt-mbr-id': calenderTeamMemberId ?? teamMemberId,
            'apt-wpl-id': workplaceId,
          }).toString(),
        });
      }
    },
    [navigateWithSearchParams],
  );

  if (isLoadingAppointments || workplacesLoading || membersLoading || !workplaceId || !viewMode || !calendarDate) {
    return <Loader />;
  }

  return (
    <>
      <CalendarHeader />

      <CalendarComponent
        backgroundEvents={backgroundEvents}
        calendarDate={calendarDate}
        events={[...appointments, ...(timeOffEvents ?? [])]}
        handleSelectAppointmentTime={handleSelectAppointmentTime}
        members={showingWorkplaceCalendarMembers}
        viewMode={viewMode}
      />
      <NewCalendarItemNotification />
      {action === 'add' && <AddEditBlockTime />}
    </>
  );
}
