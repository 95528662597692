import styled from 'styled-components';

export const ConfirmationContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.core.sizing[2]}px;

  p {
    color: ${({ theme }) => theme.core.colors.greyscale.dark};
  }
`;
